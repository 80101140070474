import { createSlice } from '@reduxjs/toolkit';
import { getFbFeed } from '../api/apiCalls';

interface InitState {
  list: any; // find types
  status: string;
  error: string | null;
}

interface InitGroupedPosts {
  prayvlog: any[];
  others: any[];
}
const initState: InitState = {
  list: [],
  status: '',
  error: null,
};

const postsSlice = createSlice({
  name: 'posts',
  initialState: initState,
  reducers: {
    requestPosts(state) {
      state.status = 'pending';
      state.error = null;
    },
    receivedPosts(state, action) {
      let initGroupedPosts: InitGroupedPosts = {
        prayvlog: [],
        others: [],
      };
      if (!state.list.data?.length) {
        state.list = action.payload;
      } else {
        state.list.data = [...state.list.data, ...action.payload.data];
        state.list.paging = action.payload.paging;
        initGroupedPosts = {
          prayvlog: [...state.list.groupedPosts.prayvlog],
          others: [...state.list.groupedPosts.others],
        };
      }

      const groupedPosts = action.payload.data.reduce((acc, curr) => {
        if (curr.message?.includes('#pray_vlog')) {
          acc.prayvlog.push(curr);
        } else {
          acc.others.push(curr);
        }
        return acc;
      }, initGroupedPosts);
      state.list.groupedPosts = groupedPosts;
      state.status = 'success';
      state.error = null;
    },
    rejectedPosts(state, action) {
      state.status = 'rejected';
      state.error = action.payload.error.message;
    },
  },
});

const { actions, reducer } = postsSlice;
export const { requestPosts, receivedPosts, rejectedPosts } = actions;
export default reducer;

// thunks

export function fetchPosts(after?) {
  return async (dispatch) => {
    dispatch(requestPosts());
    try {
      const postsData = await getFbFeed(after);
      dispatch(receivedPosts(postsData));
    } catch (err) {
      console.log(err);
      dispatch(rejectedPosts(err));
    }
  };
}

import { Launch } from "@material-ui/icons";
import { GetApp } from "@material-ui/icons";
import React from "react";

export const LinkStyle = (props: any) => {
  if (props.href) {
    return (
      <a
        {...props}
        style={{
          fontWeight: `${props.bold ? "bolder" : "normal"}`,
          color: "darkblue",
          textDecoration: "underline",
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        {props.children}
        {props.target?.includes("blank") && <Launch fontSize="small" />}
        {props.download && <GetApp fontSize="small" />}
      </a>
    );
  } else {
    return (
      <span
        {...props}
        style={{
          fontWeight: `${props.bold ? "bolder" : "normal"}`,
          color: "darkblue",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {props.children}
      </span>
    );
  }
};

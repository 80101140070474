import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../redux/postsSlice';
import PostsList from 'components/PostsList/PostsList';
import { Button } from '@material-ui/core';

export default function PrayVlogPage() {
  const dispatch = useDispatch();
  const posts = useSelector(
    (state) => state.posts.list?.groupedPosts?.prayvlog
  );
  const allPosts = useSelector((state) => state.posts.list?.data);

  const after = useSelector(
    (state) => state.posts.list?.paging?.cursors?.after
  );

  useEffect(() => {
    !allPosts?.length && dispatch(fetchPosts());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPosts]);
  return (
    <div>
      <PostsList posts={posts || []} />
      {posts?.length ? (
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => after && dispatch(fetchPosts(after))}
          >
            Więcej postów
          </Button>
        </div>
      ) : (
        <div>Brak postow</div>
      )}
    </div>
  );
}

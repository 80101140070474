import { ChallengesList } from "./../ChallengesList";
import {
  Dialog,
  DialogTitle,
} from "@material-ui/core";
import React, { FC } from "react";

interface ModalWihtChallengesProps {
  isOpen: boolean;
  handleDialogClose: () => void;
}

export const ModalWihtChallenges: FC<ModalWihtChallengesProps> = ({
  isOpen,
  handleDialogClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">Plan ascez</DialogTitle>
      <ChallengesList />
    </Dialog>
  );
};

import React from "react";
import { FacebookProvider, Initialize } from "react-facebook";
import AppContainer from "./containers/AppContainer";
import "./globalStyles.scss";
// import './App.css';

function App() {
  return (
    // <div className='App'>
    <FacebookProvider appId="185634822734576">
      <Initialize>
        {({ isReady, api }) => {
          return isReady && <AppContainer />;
        }}
      </Initialize>
    </FacebookProvider>
    // </div>
  );
}

export default App;

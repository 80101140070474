export const localStorage = window.localStorage;

export const getStorageItem = (key: string) => {
  return localStorage.getItem(key) !== null
    ? SON.parse(localStorage.getItem(key) as string)
    : null;
};

export const setStorageItemArr = (key: string, value: any) => {
  const objFromLocalStorage = [getStorageItem(key)];
  if (objFromLocalStorage) {
    const updatedObj = JSON.stringify([...objFromLocalStorage, value]);
    return localStorage.setItem(key, updatedObj);
  }
  return localStorage.setItem(key, JSON.stringify(value));
};

import { Button, Divider, Input, Paper, TextField, Typography } from "@material-ui/core";
import { Spacing } from "components/ui/Spacing";
import React, { useEffect, useState } from "react";
import { localStorage } from "utils/localStorage";
import EditIcon from "@material-ui/icons/Edit";
import { Label } from "@material-ui/icons";

const INTENCJA_STORAGE_KEY = "intencjaWielkiPostMsj";

export const WielkiPostIntencje = (props) => {
  const [intencja, setIntencja] = useState<string>("");
  const [intencjaTemp, setIntencjaTemp] = useState<string>("");

  useEffect(() => {
    const intencjaFromStorage = localStorage.getItem(INTENCJA_STORAGE_KEY);

    if (intencjaFromStorage) {
      setIntencja(intencjaFromStorage);
    }
  }, []);

  //   useEffect(() => {

  //   }, [intencjaTemp])

  return (
    <Paper style={{ padding: "30px", marginTop: '30px' }}>
      <Typography component="p">
        <Spacing vertical={3}>
          Za naszych kapłanów, aby dobry Bóg umacniał ich w codziennej posłudze
          oraz o nowe powołania do kapłaństwa i życia konsekrowanego
        </Spacing>
        <Divider />
        <Spacing vertical={3}>
          Za wszystkie osoby podejmujące te ćwieczenia duchowe, aby ten czas
          pomagał nam doświadczyć Bożej miłosći i bliskości oraz był okazją do
          pogłębienia osobistej relacji z Bogiem.
        </Spacing>
        <Divider />
        <Spacing vertical={3}>
          {!!intencja.length ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{intencja}</div>
              <Button onClick={() => setIntencja("")} variant="contained">
                <EditIcon />
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextField
                value={intencjaTemp}
                onChange={(e) => setIntencjaTemp(e.target.value)}
                id="outlined-basic"
                label="Twoja intencja"
                variant="outlined"
                placeholder="Tutaj wpisz swoją intencję"
              />

              <Button
                onClick={() => {
                  localStorage.setItem(INTENCJA_STORAGE_KEY, intencjaTemp);
                  setIntencja(intencjaTemp);
                }}
                variant="contained"
              >
                Zapisz
              </Button>
            </div>
          )}
        </Spacing>
      </Typography>
    </Paper>
  );
};

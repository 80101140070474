import React from 'react';
import './CalendarTile.scss';

export default function CalendarTile({ event, onClick }) {
	return (
		<div onClick={onClick} className={`calendar-tile ${event ? 'calendar-tile__event-name' : ''}`}>
			{event.name}
		</div>
	);
}

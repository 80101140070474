import { Button, Typography } from "@material-ui/core";
import { differenceInCalendarDays, differenceInDays, format } from "date-fns";
import pl from "date-fns/locale/pl";
import React, { FC } from "react";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";

import "./DateDisplay.scss";
import { wielkiPostStartDay } from "./data/wielkiPostData";
interface DateDisplayProps {
  date: Date;
  goDayBack: () => void;
  goDayForward: () => void;
  daysDistance: number;
  // startDay: Date;
  daysToWielkiPostAbsolute: number;
  daysToWielkiPostRelative: number;
}
export const DateDisplay: FC<DateDisplayProps> = ({
  date,
  goDayBack,
  goDayForward,
  daysDistance,
  // startDay,
  daysToWielkiPostAbsolute,
  daysToWielkiPostRelative,
}) => {
  const dayOfWeek: string = format(date, "EEEE", { locale: pl });

  return (
    <div>
      <div className="date-display">
        <Button onClick={goDayBack} disabled={daysToWielkiPostRelative >= 0}>
          <ChevronLeftRoundedIcon />
        </Button>
        <div>
          <Typography variant="h5" display="block" align="center">
            {dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)}
          </Typography>
          {daysToWielkiPostRelative < 1 && (
            <Typography variant="h4" display="block" align="center">
              Dzień {differenceInDays(date, wielkiPostStartDay) + 1}
            </Typography>
          )}

          <Typography variant="h5" display="block">
            {format(date, "do MMM yyyy")}{" "}
          </Typography>
        </div>
        {daysDistance ? (
          <Button onClick={() => goDayForward()}>
            <ChevronRightRoundedIcon />
          </Button>
        ) : (
          <Button disabled>
            <ChevronRightRoundedIcon />
          </Button>
        )}
      </div>
      {daysToWielkiPostAbsolute > 0 && (
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          style={{ marginTop: "20px" }}
        >
          Wielki Post zaczyna sie za {daysToWielkiPostAbsolute}{" "}
          {daysToWielkiPostAbsolute === 1 ? "dzień" : "dni"}
        </Typography>
      )}
    </div>
  );
};

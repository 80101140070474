import React from 'react';
import Grid from '@material-ui/core/Grid';
import SectionTitle from 'components/ui/SectionTitle';
import EventCard from 'components/EventCard';

export default function EventsList(props) {
	return (
		<section>
			<SectionTitle title='Wydarzenia' />
			<Grid wrap='nowrap' direction='column' justify='center' alignItems='stretch' container spacing={2}>
				{props.events.map(event => (
					<Grid item xs={12} key={event.id}>
						<EventCard event={event} showImage />
					</Grid>
				))}
			</Grid>
			<div></div>
		</section>
	);
}

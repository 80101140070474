import React from "react";
import { Paper, Typography, Container, Divider } from "@material-ui/core";
import Page from "components/ui/Page";

function Modlitwa({ title, children }) {
  return (
    <Container maxWidth="md" style={{ marginBottom: "20px" }}>
      <Paper style={{ padding: "40px 20px" }} elevation={5}>
        <Typography variant="h5" gutterBottom>
          {title}
          <Divider />
        </Typography>
        <Typography variant="body1">{children}</Typography>
      </Paper>
    </Container>
  );
}

export default function ModlitwyPage() {
  return (
    <Page>
      <Modlitwa title="Modlitwa codzienna do świętego Józefa">
        O święty Józefie, mniemany Ojcze i Opiekunie Pana i Zbawiciela naszego
        Jezusa Chrystusa, najmilszy Oblubieńcze Matki Jego, Przeczystej Dziewicy
        Maryi! Ja, N.N. Ciebie obieram sobie dziś i po wszystkie dni mego życia
        za Patrona, Ojca i Opiekuna i postanawiam mocno głosić i rozszerzać
        zawsze i wszędzie Twoją chwałę i cześć. Błagam Cię pokornie, racz mnie
        przyjąć za swego sługę. Ratuj mnie we wszystkich potrzebach duszy i
        ciała, broń od wszystkich nieprzyjaciół widzialnych i niewidzialnych,
        zachowaj mnie do wszelkiego niebezpieczeństwa, nieszczęścia i złego
        przypadku. Szczególnie zaś w godzinę mej śmierci przybądź, wspieraj i
        ratuj mnie wraz z Niepokalaną Oblubienicą Twoją Maryją, abym nie zginął,
        ale miał żywot wieczny i z Wami wielbił Boga po wszystkie wieki wieków.
        Amen.
      </Modlitwa>
      <Modlitwa title="Modlitwa poranna">
        Święty Józefie, mój najmilszy Opiekunie, w poranek dzisiejszego dnia
        oddaję się pod Twoją szczególną opiekę. Dopomóż mi w pracy i w
        spełnianiu moich obowiązków, broń od grzechu, uproś mi potrzebne cnoty,
        zachowaj od wszelkich niebezpieczeństw duszy i ciała. Błagam Cię, święty
        Józefie, czuwaj nade mną, abym zawsze szedł drogą cnoty, a nigdy nie
        zbaczał na drogi występku. Niech za Twoim przykładem ukocham Boga ponad
        wszystko, pełniąc dzisiaj i zawsze Jego święte przykazania i niech przy
        Twojej pomocy przyczynię się do zbawienia moich bliźnich. Amen
      </Modlitwa>
      <Modlitwa title="Akt poświęcenia się świętemu Józefowi.">
        Święty Józefie, mój najmilszy Ojcze, po Jezusie i Maryi najdroższy
        mojemu sercu, Tobie się powierzam i oddaję, jak powierzyli się Twej
        opiece Boży Syn i Jego Przeczysta Matka. Przyjmij mnie za swoje dziecko,
        bo ja na całe życie obieram Cię za Ojca, Opiekuna, Obrońcę i Przewodnika
        mej duszy. O mój najlepszy Ojcze, święty Józefie, prowadź mnie prostą
        drogą do Jezusa i Maryi. Naucz mnie kochać wszystkich czystą miłością i
        być gotowym do poświęceń dla bliźnich. Naucz walczyć z pokusami ciała,
        świata i szatana i znosić w cichości każdy krzyż, jaki mnie spotka.
        Naucz pokory i posłuszeństwa woli Bożej. O najdroższy święty Józefie,
        bądź Piastunem mej duszy, odkupionej Krwią Chrystusa. Czuwaj nade mną,
        jak strzegłeś Dzieciątka Jezus, a ja Ci przyrzekam wierność, miłość i
        całkowite posłuszeństwo, bo ufam, że za Twym wstawiennictwem będę
        zbawiony. Nie patrz na moją nędzę, ale dla miłości Jezusa i Maryi
        przyjmij mnie pod swą Ojcowską opiekę. Amen.
      </Modlitwa>
    </Page>
  );
}

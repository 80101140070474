import Page from "components/ui/Page";
import React from "react";

export const MDSPage = () => {
  return (
    <Page>
        <iframe
          title="rejestracja"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfPqHXOE9mxDCxzSoccHYEMveMZ6ICk0jWTnObkhaL4oZWlOw/viewform?embedded=true"
          width="100%"
          height="1718"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
        >
          Loading…
        </iframe>
    </Page>
  );
};

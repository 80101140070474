import { Container, Typography } from "@material-ui/core";
import Page from "components/ui/Page";
import { Spacing } from "components/ui/Spacing";
import React from "react";
import wzgorzedecyzji from "../assets/wzgorzedecyzji.png";

export const ExodusPage = () => {
  return (
    <Page>
      <Typography variant="h3" component="h1">
        Exodus91 - Wzgórze Decyzji
      </Typography>
      <br />
      <img src={wzgorzedecyzji} width="100%" height="100%" alt="" />
      <Typography variant="body1">
        <br />
        <br />
        <strong>JAKIM MĘŻCZYZNĄ CHCESZ BYĆ?</strong>
        <br />
        <br />
        Wyobraź sobie co mógłbyś osiągnąć, gdybyś pozbył się wszystkiego, co
        stoi na twojej drodze: rozpraszaczy, samotności, braku wiary w samego
        siebie… Ćwiczenia z cyklu Exodus 91 dają ci konkretny i realny plan
        działania, aby przezwyciężyć rozproszenie i samotność poprzez modlitwę,
        pokutę i braterstwo.
        <br />
        <br />
        Zapraszamy do kolejnego już ćwiczenia duchowego online podejmowanego
        przez MSJ Glasgow. Decision Dome - Wzgórze Decyzji to 42 dni bliskiej
        współpracy z Jezusem oraz braćmi ze wspólnoty ku zbliżaniu się do
        zbawienia.
        <br />
        <br />
        W trakcie tego ćwiczenia będziesz obserwował, jak Izraelici przygotowują
        się do wejścia do ziemi obiecanej. Wkrótce wkroczysz do ziemi obiecanej
        na dalekim wschodzie, kierując się na zachód. Choć brzmi to wspaniale,
        szybko zdasz sobie sprawę, że objęcie w posiadanie ziemi obiecanej nie
        będzie tak łatwe, jak się spodziewałeś.
        <br />
        <br />
        Kiedy dotrzesz na szczyt Wzgórza Decyzji, dotrzesz do punktu
        kulminacyjnego Księgi Jozuego i przejścia tego szczytu o tej samej
        nazwie. „Wybierz dziś, komu będziesz służył, czy bogom, którym służyli
        twoi ojcowie w krainie za rzeką, czy też bogom Amorytów, w których ziemi
        mieszkasz; ale ja sam i dom mój służyć chcemy Panu” (Jozuego 24:15).
        Każdego dnia życia wybieramy, komu będziemy służyć, czy o tym wiemy, czy
        nie. Dyscyplina modlitwy, ascezy i braterstwa, które podejmujesz w
        czasie wędrówki na Wzgorze Decyzji, pomoże ci dostrzec powagę tej zbyt
        często pomijanej decyzji i pomoże ci wybrać celowe służenie Panu każdego
        dnia twojego życia.
        <br />
        <br />
        Witamy u stóp Wzgórza Decyzji. Potrzeba wytrwałości aby się na niego
        wdrapać. Zaryzykujesz wędrówkę?
        <br />
        <br />
        Start: 15/08/2021
        <br />
        Dołącz wchodząc na:{" "}
        <a
          style={{ color: "darkblue", textDecoration: "underline" }}
          href="https://app.exodus90.com/j/b699qze"
          rel="noopener noreferrer"
          target="_blank"
        >
          app.exodus90.com/j/b699qze
        </a>
        <br />
        lub sciągnij aplikacje Exodus90 i dołącz do bractwa używając kodu:{" "}
        <b>B699QZE</b>.
      </Typography>
    </Page>
  );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UpcomingEvents from 'components/UpcomingEvents';
import { fetchPosts } from '../redux/postsSlice';
import { fetchEvents } from '../redux/eventsSlice';
import PostsList from 'components/PostsList/PostsList';
import SmallLink from 'components/ui/SmallLink';

import './HomeContainer.scss';
import { getFutureEvents } from 'utils';

export default function HomeContainer() {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.list?.data);
  const events = useSelector((state) => state.events.list?.data);

  const lastThreePosts = posts && posts.slice(0, 3);

  useEffect(() => {
    !posts?.length && dispatch(fetchPosts());
    !events?.length && dispatch(fetchEvents());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const upcomingEvents = getFutureEvents(events);

  return (
    <div>
      <div className="home__image" />
      {!!upcomingEvents?.length && <UpcomingEvents events={events || []} />}
      <section className="last-posts">
        <PostsList posts={lastThreePosts || []} />
        <div className="last-posts__more-link">
          <SmallLink to="/posty">Zobacz wszystkie!</SmallLink>
        </div>
      </section>
    </div>
  );
}

import React from "react";
import ReactPlayer from "react-player";
import { Typography } from "@material-ui/core";
import Page from "components/ui/Page";

export default function AboutPage() {
  return (
    <Page>
      <Typography variant="h4" gutterBottom>
        Kim są Mężczyźni św. Józefa?
      </Typography>
      <Typography variant="body1" paragraph>
        Mężczyźni Świętego Józefa to sieć mężczyzn, którzy pragną w swoim życiu
        duchowym czegoś więcej. To mężczyźni, którzy:
      </Typography>
      <Typography variant="body1">
        <ul>
          <li>
            szukają Pana: “Będziecie Mnie szukać i znajdziecie Mnie, albowiem
            będziecie Mnie szukać z całego serca”. (Jer 29,13)
          </li>
          <li>
            starają się służyć potrzebom innych zamiast szukać władzy i
            przywilejów. (Łk 22,25n) trwają w Bożej mocy (J 15)
          </li>
          <li>odkrywają radość życia według Bożego planu. (Kol 1,9-12)</li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph>
        Pragniemy wspólnie wzrastać w tej wizji. Jeśli chcesz – możesz do nas
        dołączyć.
      </Typography>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=vxRF9OCcUFU&feature=emb_logo"
        controls
        width="100%"
        style={{ maxWidth: "600px" }}
      />
      <br />
      <Typography variant="h4" gutterBottom>
        Kim są Mężczyźni Świętego Józefa?
      </Typography>
      <Typography variant="body1" paragraph>
        W dzisiejszych czasach istnieje wiele przeszkód na drodze wiary, ale
        Mężczyzna Świętego Józefa to człowiek, który szuka Pana w swoim życiu:
        (Jer 29,13) “Będzie Mnie szukać i znajdziecie Mnie, kiedy będziecie Mnie
        szukać z całego serca”. Nasz Pan Jezus Chrystus przyszedł, aby pokazać
        nam Bożą Miłość i tak odkrywamy naszą rolę jako mężczyzn, którzy
        dostrzegają potrzeby inne niż poszukiwanie mocy i przywilejów (Łk
        22,25n.). Jednak to wezwanie nie jest ciężarem złożonym własnych
        barkach, jako mężczyźni otrzymujemy wsparcie od towarzyszy którzy
        "wspierają się nawzajem i wzajemnie się budują" (1Tes 5,11) i trwają w
        Bogu(Jan 15). W rezultacie Mężczyzna Świętego Józefa jest spełniony
        przez odkrywanie radości życia Bożego w celach, jakie odkrywa dla
        swojego życia (Kol. 1,9-12). W skrócie: jak św. Józef był najbliższym
        człowiekiem Jezusa, tak my staramy się być ludźmi, którzy zobowiązują
        się wobec Jezusa Chrystusa naszego Pana i Zbawiciela.
      </Typography>
      <Typography variant="body1" paragraph>
        Mężczyźni Świętego Józefa to katolicka siec mężczyzn, którzy powierzyli
        swoje życie Jezusowi Chrystusowi.
      </Typography>
      <Typography variant="h4">Zaproszenie:</Typography>
      <Typography variant="body1" paragraph>
        Zapraszamy mężczyzn do zaakceptowania poniższych obietnic jako
        najlepszych dla ich życia:
      </Typography>
      <Typography variant="body1" paragraph>
        <ol>
          <li>
            Poświęć życie dla Pana i uczyń Go pierwszym we wszystkich
            dziedzinach swojego życia... Uczestnicz w niedzielnej Eucharystii
            wraz ze swoją rodziną.
          </li>
          <li>
            Wzrastaj w chrześcijańskim uczniostwie... Rozwijaj modlitwę osobistą
            i uczestnicz w Eucharystii także częściej (w dni powszednie).
          </li>
          <li>
            Żyj w Prawości i Uczciwości... Korzystaj regularnie z Sakramentu
            Pojednania.
          </li>
          <li>
            Bądź wierny Chrześcijańskiemu Powołaniu... Módl się, spędzaj czas z
            rodziną, ustaw priorytety i zapewnij równowagę w swoim życiu.
          </li>
          <li>
            Spotykaj się regularnie z braćmi by się wzajemnie wspierać… Zbieraj
            się razem z nimi na konferencjach, okresowych spotkaniach,
            rekolekcjach.
          </li>
        </ol>
      </Typography>
      <Typography variant="caption" paragraph>
        Źródło:{" "}
        <a
          href="http://mezczyzni.net/main/o-nas"
          target="blank"
          rel="noopener noreferrer"
        >
          http://mezczyzni.net/main/o-nas
        </a>
      </Typography>
    </Page>
  );
}

import { Button, ButtonGroup } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";

export enum SelectedViews {
  "app",
  "ascezy",
  "intencje",
  "info",
}

interface ViewSelectionButtonsProps {
  selectedView: SelectedViews;
  onChange: (selectedView: SelectedViews) => void;
  daysToWielkiPost: number;
}
export const ViewSelectionButtons: FC<ViewSelectionButtonsProps> = (props) => {
  const [selectedView, setSelectedView] = useState<SelectedViews>(
    SelectedViews.app
  );
  
  useEffect(() => {
    if(props.daysToWielkiPost > 0) {
        props.onChange(SelectedViews.info)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.daysToWielkiPost])

  useEffect(() => {
    setSelectedView(props.selectedView);
  }, [props.selectedView]);

  const getButtonStyle = (view: SelectedViews) => ({
    background: isActive(view) ? "linear-gradient( 100deg , darkslateblue, #8532b9)" : "white",
    color: isActive(view) ? "white" : "",
    padding: '10px 32px'
  });

  const isActive = (view: SelectedViews) => view === selectedView;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: '20px',
        flexFlow: 'column',
        // alignItems: 'stretch'
      }}
    >
      <ButtonGroup size="large" >
        <Button
          style={getButtonStyle(SelectedViews.app)}
          onClick={() => props.onChange(SelectedViews.app)}
        >
          App
        </Button>

        <Button
          onClick={() => props.onChange(SelectedViews.intencje)}
          style={getButtonStyle(SelectedViews.intencje)}
        >
          Intencje
        </Button>
        <Button
          onClick={() => props.onChange(SelectedViews.ascezy)}
          style={getButtonStyle(SelectedViews.ascezy)}
        >
          Ascezy
        </Button>
        <Button
          onClick={() => props.onChange(SelectedViews.info)}
          style={getButtonStyle(SelectedViews.info)}
        >
          Info
        </Button>
      </ButtonGroup>
    </div>
  );
};

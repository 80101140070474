import React from "react";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Page from "components/ui/Page";

export default function OredownikPage() {
  return (
    <Page>
      <Typography variant="h4" gutterBottom>
        Nasz orędownik
      </Typography>
      <Typography variant="body1" paragraph>
        <p>
          Święty Józef był mężem Maryi. Jego postać znamy ze stron Ewangelii.
          Całe swoje życie św. Józef spędził jako rękodzielnik i wyrobnik w
          ciężkiej zarobkowej pracy. Ewangelie określają go mianem faber, przez
          co rozumiano wyrobnika - rzemieślnika od naprawy narzędzi rolniczych,
          przedmiotów drewnianych itp. Były to więc prace związane z
          budownictwem, z robotą w drzewie i w żelazie. Józef wykonywał na
          zamówienie bardzo ciężkie prace i z nich utrzymywał Najświętszą
          Rodzinę. Ta właśnie praca stała się równocześnie dla niego źródłem
          uświęcenia.
        </p>
        <br />
        <p>
          Był on mistykiem nie przez kontemplację, przez uczynki pokutne czy
          przez dzieła miłosierdzia, ale przez pracę. Praca go uświęciła, gdyż
          wykonywał ją rzetelnie, nie żądał za nią wygórowanych cen, wypełniał
          ją cicho i pokornie jako zleconą sobie od Boga misję na ziemi.
          Spełniał ją zapatrzony w Jezusa i Maryję. Dla nich żył, dla nich się
          trudził, dla nich był gotów do najwyższych ofiar. Równocześnie był
          charyzmatykiem – mężem otwartym na działanie i prowadzenie Ducha
          Świętego. To właśnie dzięki swojej wrażliwości na Boga mógł w sposób
          doskonały zrealizować plan Boga i zapewnić ochronę Jezusowi w czasie
          największego zagrożenia.
        </p>
        <br />
        <p>
          Taki powinien być styl pracy każdego chrześcijańskiego mężczyzny.
          Praca ma go uświęcać, równocześnie pielęgnując relację z Bogiem w
          Duchu Świętym powinien on wzrastać we wrażliwości duchowej i obecności
          Bożej. Takie połączenie pozwala najpełniej rozwinąć jego uzdolnienia,
          energię, inicjatywę. Jest szkołą wielu cnót osobistych i społecznych,
          takich jak na przykład wytrzymałość, solidarność, cierpliwość, męstwo,
          odwaga i ład, współpraca, współzawodnictwo. Józef pochodził z rodu
          króla Dawida. Wykazuje to św. Mateusz w genealogii przodków św.
          Józefa.
        </p>
      </Typography>
      <Typography variant="caption">Na podstawie: brewiarz.pl</Typography>

      <ExpansionPanel style={{ marginTop: "20px" }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Świadectwo św.Teresy z Ávili </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography variant="body1">
            Obrałam sobie za orędownika i patrona chwalebnego świętego Józefa,
            usilnie jemu się polecając. I poznałam jasno, że jak w tej
            potrzebie, tak i w innych pilniejszych jeszcze, w których chodziło o
            cześć moją i o zatracenie duszy, Ojciec ten mój i Patron wybawił mię
            i więcej mi dobrego uczynił niż sama prosić umiałam. Nie pamiętam,
            bym kiedykolwiek aż do tej chwili prosiła go o jaką rzecz, której by
            mi nie wyświadczył. Jest to rzecz zdumiewająca, jak wielkie rzeczy
            Bóg mi uczynił za przyczyną tego chwalebnego Świętego, z ilu
            niebezpieczeństw na ciele i na duszy mię wybawił. Innym Świętym,
            rzec można, dał Bóg łaskę wspomagania nas w tej lub owej potrzebie,
            temu zaś chwalebnemu Świętemu, jak o tym wiem z własnego
            doświadczenia, dał władzę wspomagania nas we wszystkich. Chciał nas
            Pan przez to upewnić, że jak był mu poddany na ziemi jako opiekunowi
            i mniemanemu ojcu swemu, który miał prawo Mu rozkazywać — tak i w
            niebie czyni wszystko, o cokolwiek on Go prosi.
            <br />
            <br />
            Przekonali się o tym i inni, którym poradziłam, aby się jemu
            polecili, i coraz więcej jest już takich, którzy go czczą i wzywają,
            doznając na sobie tej prawdy.
            <br />
            <br />( Życie,VI,6)
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Page>
  );
}

import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import './WielkiPostActionItem.scss';

export enum WielkiPostActionId {
  czytanie = 'czytanie',
  challenge = 'challenge',
  modlitwa = 'modlitwa',
}
interface WielkiPostActionItemProps {
  // currentData: WielkiPostDayData | undefined;
  actionId: WielkiPostActionId;
  children: any;
  onItemClick: (dayIndex: number, actionId: WielkiPostActionId) => void;
  dayIndex: number;
  isChecked: boolean | undefined;
}

export const WielkiPostActionItem: React.FC<WielkiPostActionItemProps> = ({
  children,
  // currentData,
  actionId,
  onItemClick,
  dayIndex,
  isChecked,
}) => {
  return (
    <Paper
      onClick={() => {
        onItemClick(dayIndex, actionId);
      }}
      className='wielkipost-action-item'
    >
      <CheckCircleOutlineIcon
        fontSize='large'
        className={`wielkipost-action-item__icon ${isChecked ? 'wielkipost-action-item__icon--checked' : ''}`}
      />
      {children}
    </Paper>
  );
};

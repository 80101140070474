import React from "react";
import { challenges } from "components/data/wielkiPostData";
import { Divider, List, ListItem, Typography } from "@material-ui/core";
import { LinkStyle } from "./ui/LinkStyle";
import wielkipostPlanImage from 'assets/wielkipostPlan.png'

export function ChallengesList() {
  const weekDays = [
    "Niedziela",
    "Poniedziałek",
    "Wtorek",
    "Środa",
    "Czwartek",
    "Piątek",
    "Sobota",
  ];
  return (
    <div style={{marginTop: '20px'}}>
      <List>
        {Object.values(challenges).map((item: string, i: number) => (
          <Typography key={item}>
            <ListItem
              style={{
                padding: "15px",
              }}
            >
              <div>
                <strong>{weekDays[i]}</strong> : {item}
              </div>
            </ListItem>
            <Divider />
          </Typography>
        ))}
      </List>
      <Typography
        style={{
          padding: "15px",
          textAlign: "center",
        }}
      >
        <LinkStyle href={wielkipostPlanImage} download>Pobierz plan</LinkStyle>
      </Typography>
    </div>
  );
}

import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
} from "@material-ui/core";
import { VideoLivePost } from "components/PostItem/PostTypes";
import { Spacing } from "components/ui/Spacing";
import {
  WielkiPostActionId,
  WielkiPostActionItem,
} from "components/WielkiPostActionItem";
import { WielkiPostDayData, challenges } from "components/data/wielkiPostData";
import { IVideoPost } from "pages/WielkiPostPageDev";
import React, { FC } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

interface WielkiPostAppCodeProps {
  isItemAlreadyDone: (
    dayId: number,
    actionId: WielkiPostActionId
  ) => boolean | undefined;
  displayDateData: WielkiPostDayData;
  saveOrRemoveFromLocalStorage: (
    dayId: number,
    actionId: WielkiPostActionId
  ) => void;
  displayDateVideo: IVideoPost;
}

export const WielkiPostAppCore: FC<WielkiPostAppCodeProps> = ({
  isItemAlreadyDone,
  displayDateData,
  saveOrRemoveFromLocalStorage,
  displayDateVideo,
}) => {
  return (
    <>
      <WielkiPostActionItem
        isChecked={isItemAlreadyDone(
          displayDateData?.dayIndex,
          WielkiPostActionId.challenge
        )}
        onItemClick={saveOrRemoveFromLocalStorage}
        dayIndex={displayDateData ? displayDateData.dayIndex : 0}
        actionId={WielkiPostActionId.challenge}
      >
        <Typography component="p">
          {displayDateData && challenges[displayDateData?.dayOfWeek]}
        </Typography>
      </WielkiPostActionItem>

      <WielkiPostActionItem
        isChecked={isItemAlreadyDone(
          displayDateData?.dayIndex,
          WielkiPostActionId.czytanie
        )}
        onItemClick={saveOrRemoveFromLocalStorage}
        dayIndex={displayDateData ? displayDateData.dayIndex : 0}
        actionId={WielkiPostActionId.czytanie}
      >
        <Typography component="p">Rożwazanie słowa</Typography>
      </WielkiPostActionItem>

      <WielkiPostActionItem
        isChecked={isItemAlreadyDone(
          displayDateData?.dayIndex,
          WielkiPostActionId.modlitwa
        )}
        onItemClick={saveOrRemoveFromLocalStorage}
        dayIndex={displayDateData ? displayDateData.dayIndex : 0}
        actionId={WielkiPostActionId.modlitwa} // TODO: change
      >
        <Typography component="p">Medytacja</Typography>
      </WielkiPostActionItem>
      <div style={{ whiteSpace: "pre-line", margin: "30px 0px" }}>
        <Accordion style={{ padding: "10px" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="czytanie-content"
            id="czytanie-header"
          >
            <Typography>Czytanie na dziś</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ whiteSpace: "pre-line", margin: "30px 10px" }}>
              <Typography variant="h6">
                {displayDateData.czytanie.ewangelia}
              </Typography>
              <Typography variant="body1">
                <i>{displayDateData.czytanie.title}</i>
              </Typography>
              <Typography variant="body2">
                <strong>{displayDateData.czytanie.slowaEwangelii}</strong>
              </Typography>
              <Typography variant="body1">
                {displayDateData.czytanie.text}
              </Typography>
            </div>
          </AccordionDetails>
        </Accordion>
        <Spacing bottom={2} />
        {displayDateVideo && (
          <Paper style={{ padding: "40px 20px" }} elevation={5}>
            <Typography component="p">{displayDateVideo.message}</Typography>
            <VideoLivePost post={displayDateVideo} />
          </Paper>
        )}
      </div>
    </>
  );
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../redux/eventsSlice";
import EventsList from "components/EventsList";
import Page from "components/ui/Page";

export default function EventsContainer() {
  // const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.list?.data);

  useEffect(() => {
    !events?.length && dispatch(fetchEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <EventsList events={events || []} />
    </Page>
  );
}

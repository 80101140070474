import { add, getDay } from 'date-fns';
import { czytania } from './wielkiPostCzytania';

export interface ICzytanie {
  ewangelia: string;
  title?: string;
  slowaEwangelii: string;
  text: string;
}
export interface WielkiPostDayData {
  dayIndex: number;
  dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  date: Date;
  czytanie: ICzytanie;
}

// Niedziela jest pierwsza
export const challenges = {
  0: 'Poświęć 30 min na adorację Najświętszego Sakramentu (online jeżeli nie ma innej możliwości). 5 min - podziękować Bogu/ 20 min - modlitwy cichej i słuchanie Boga/ 5min - modlitwa wstawiennicza za innych i w naszych intencjach',
  1: 'Weź zimny, krótki prysznic',
  2: 'Powstrzymaj sie od słodyczy i deserów',
  3: 'Powstrzymaj sie od niekoniecznego używania urządzeń elektroniczych (tv, telefon, komputer, konsole)',
  4: 'Powstrzymaj sie od spożywania słodkich napojów (kawa i herbata bez cukry dozwolone)',
  5: 'Post ścisły - dwa posiłki mniejsz + jeden do syta (bez mięsa)',
  6: 'Powstrzymaj sie od spożywania alkoholu',
};

const startDay = new Date('2021-02-17');
//  const startDay = new Date("2021-02-17");
export const wielkiPostStartDay = process.env.NODE_ENV === 'production' ? new Date('2021-02-17') : startDay;
export const wielkiPostEndDay = new Date('2021-04-01');

const dates = new Array(7).fill('').map((_, i: number) => add(wielkiPostStartDay, { days: i }));

export const wielkiPostData1: WielkiPostDayData[] = [
  {
    dayIndex: 1,
    dayOfWeek: getDay(dates[0]),
    date: dates[0],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów:  
        «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.  \
        Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».`,
    },
  },
  {
    dayIndex: 2,
    dayOfWeek: getDay(dates[1]),
    date: dates[1],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów: «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.
      Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.
      Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.
      Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».
     `,
    },
  },
  {
    dayIndex: 3,
    dayOfWeek: getDay(dates[2]),
    date: dates[2],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów:  

        «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.  \
      
        Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».`,
    },
  },
  {
    dayIndex: 4,
    dayOfWeek: getDay(dates[3]),
    date: dates[3],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów:  

        «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.  
      
        Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».`,
    },
  },
  {
    dayIndex: 5,
    dayOfWeek: getDay(dates[4]),
    date: dates[4],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów:  

        «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.  
      
        Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».`,
    },
  },
  {
    dayIndex: 6,
    dayOfWeek: getDay(dates[5]),
    date: dates[5],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów:  

        «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.  
      
        Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».`,
    },
  },
  {
    dayIndex: 7,
    dayOfWeek: getDay(dates[6]),
    date: dates[6],
    czytanie: {
      ewangelia: 'Ewangelia (Mt 6, 1-6. 16-18)',
      title: 'Ojciec twój, który widzi w ukryciu, odda tobie',
      slowaEwangelii: 'Słowa Ewangelii według Świętego Mateusza',
      text: `Jezus powiedział do swoich uczniów:  

        «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.  
      
        Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.  
      
        Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».`,
    },
  },
];

export const generateData = (): WielkiPostDayData[] => {
  // let index: number;

  // const startDay = new Date("2021-02-01");
  //  const startDay = new Date("2021-02-17");

  const data = czytania.map((item, i) => {
    // index = i;
    const date = add(wielkiPostStartDay, { days: i });
    return {
      dayIndex: i + 1,
      dayOfWeek: getDay(date),
      date: date,
      czytanie: {
        ewangelia: item[0],
        title: item[1],
        slowaEwangelii: item[2],
        text: item[3],
      },
    };
  });
  return data;
};

export const wielkiPostData: WielkiPostDayData[] = generateData();

import { combineReducers } from 'redux';
import posts from './postsSlice';
import events from './eventsSlice';

const rootReducer = combineReducers({
	posts,
	events,
});

export default rootReducer;

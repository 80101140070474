import Page from 'components/ui/Page';
import React, { FC, useEffect, useState } from 'react';
import { DateDisplay } from 'components/DateDisplay';
import { wielkiPostData, WielkiPostDayData, wielkiPostStartDay } from 'components/data/wielkiPostData';
import addDays from 'date-fns/addDays';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { WielkiPostActionId } from 'components/WielkiPostActionItem';
import './PostItem.scss';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPosts } from 'redux/postsSlice';

import { SelectedViews, ViewSelectionButtons } from 'components/ViewSelectionButtons/ViewSelectionButons';
import { ChallengesList } from 'components/ChallengesList';
import { WielkiPostInfo } from 'components/WielkiPostInfo';
import { WielkiPostAppCore } from 'containers/WIelkiPostAppCore';
import { Paper, Typography } from '@material-ui/core';
import { WielkiPostIntencje } from 'components/WIelkiPostIntencje/WielkiPostIntencje';

const STORAGE_KEY = 'wielkiPostMsj';
const ls = window.localStorage;

interface StorageItem {
  czytanie?: boolean;
  challenge?: boolean;
  modlitwa?: boolean;
}

export interface IVideoPost {
  full_picture: string;
  created_time: string;
  id: string;
  message: string;
  attachments: {
    data: [
      {
        media: {
          source: string;
        };
        url: string;
        type: string;
      }
    ];
  };
}

export const WielkiPostPageDev: FC<any> = (props) => {
  const [displayDate, setDisplayDate] = useState<Date>(new Date());
  const [localStorageItems, setLocalStorageItems] = useState<Record<string, StorageItem>>({});
  const [selectedView, setSelectedView] = useState<SelectedViews>(SelectedViews.app);

  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.list?.data);

  useEffect(() => {
    const items = ls.getItem(STORAGE_KEY);
    if (items) {
      setLocalStorageItems(JSON.parse(items));
    }

    !posts?.length && dispatch(fetchPosts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  useEffect(() => {
    ls.setItem(STORAGE_KEY, JSON.stringify(localStorageItems));
  }, [localStorageItems]);

  const moveDay = (days: number) => setDisplayDate(addDays(displayDate, days));
  const daysDistance = differenceInCalendarDays(new Date(), displayDate);

  const displayDateComparable = displayDate.toLocaleDateString();
  console.log(
    '🚀 ~ file: WielkiPostPageDev.tsx ~ line 85 ~ displayDateComparable',
    displayDateComparable === '04/04/2021'
  );

  const isWIELKANOC = displayDateComparable === '04/04/2021';

  const displayDateData: WielkiPostDayData | undefined = wielkiPostData.find(
    (data: WielkiPostDayData) => data.date?.toLocaleDateString() === displayDateComparable
  );

  const isItemAlreadyDone = (dayId: number, actionId: WielkiPostActionId) =>
    localStorageItems[dayId] ? localStorageItems[dayId][actionId] : false;

  const saveOrRemoveFromLocalStorage = (dayId: number, actionId: WielkiPostActionId) => {
    const currentItem: StorageItem | undefined = localStorageItems[dayId];

    //add
    if (!isItemAlreadyDone(dayId, actionId)) {
      setLocalStorageItems({
        ...localStorageItems,
        [dayId]: { ...currentItem, [actionId]: true },
      });
    } else {
      // remove
      setLocalStorageItems({
        ...localStorageItems,
        [dayId]: { ...currentItem, [actionId]: false },
      });
    }
  };

  const VIDEO_KEYWORD = /#wielki_post/i;
  const displayDateVideo: IVideoPost = posts?.filter(
    (post: IVideoPost) =>
      VIDEO_KEYWORD.test(post.message) && new Date(post.created_time).toLocaleDateString() === displayDateComparable
  )[0];

  const handleViewChange = (selectedView) => {
    console.log('BUTTON', selectedView);
    setSelectedView(selectedView);
  };

  const isActive = (view: SelectedViews) => view === selectedView;

  // const startDay = wielkiPostStartDay;
  // const startDay = new Date('2021-02-08');
  const daysToWielkiPostAbsolute = differenceInCalendarDays(wielkiPostStartDay, new Date());

  const daysToWielkiPostRelative = differenceInCalendarDays(wielkiPostStartDay, displayDate);

  if (isWIELKANOC || new Date().toLocaleDateString() === '04/04/2021') {
    return (
      <Page>
        <Typography variant='h4'>JEZUS ZMARTWYCHWSTAŁ</Typography>
      </Page>
    );
  }

  return (
    <Page>
      <DateDisplay
        date={displayDate}
        goDayForward={() => moveDay(1)}
        goDayBack={() => moveDay(-1)}
        daysDistance={daysDistance}
        // startDay={startDay}
        daysToWielkiPostAbsolute={daysToWielkiPostAbsolute}
        daysToWielkiPostRelative={daysToWielkiPostRelative}
      />
      <ViewSelectionButtons
        selectedView={selectedView}
        onChange={handleViewChange}
        daysToWielkiPost={daysToWielkiPostAbsolute}
      />

      {isActive(SelectedViews.app) &&
        (displayDateData ? (
          <WielkiPostAppCore
            isItemAlreadyDone={isItemAlreadyDone}
            displayDateData={displayDateData}
            saveOrRemoveFromLocalStorage={saveOrRemoveFromLocalStorage}
            displayDateVideo={displayDateVideo}
          />
        ) : (
          <Typography variant='h6' align='center' gutterBottom style={{ marginTop: '20px' }}>
            Wielki Post zaczyna sie za: {daysToWielkiPostAbsolute} {daysToWielkiPostAbsolute === 1 ? 'dzień' : 'dni'}
          </Typography>
        ))}
      {isActive(SelectedViews.intencje) && <WielkiPostIntencje />}
      {isActive(SelectedViews.ascezy) && (
        <Paper style={{ padding: '10px', marginTop: '30px' }}>
          <ChallengesList />
        </Paper>
      )}
      {isActive(SelectedViews.info) && <WielkiPostInfo />}
    </Page>
  );
};

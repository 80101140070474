import { Dialog, DialogTitle } from "@material-ui/core";
import React, { FC } from "react";
import appImage from "assets/sampleWielkiPostAppPage.png";

interface ModalWihtAppImageProps {
  isOpen: boolean;
  handleDialogClose: () => void;
}

export const ModalWithAppImage: FC<ModalWihtAppImageProps> = ({
  isOpen,
  handleDialogClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleDialogClose}
      aria-labelledby="app-image-dialog-title"
    >
      <DialogTitle id="app-image-dialog-title">Podgląd aplikacji</DialogTitle>
      <img src={appImage} alt="" />
    </Dialog>
  );
};

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoute from "./AppRoute";
import Navigation from "../components/Navigation";
import Footer from "components/Footer";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { Snackbar, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function AppContainer() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(
    null
  );

  // const onSWUpdate = (registration: ServiceWorkerRegistration) => {
  //   setShowReload(true);
  //   setWaitingWorker(registration.waiting);
  //   console.log('SET WORKE WAITING', registration.waiting)
  // };

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration: ServiceWorkerRegistration) => {
        alert("New content available. Ready to update?");
        setShowReload(true);
        setWaitingWorker(registration.waiting);
        registration.waiting?.postMessage({ type: "SKIP_WAITING" });
        console.log("SET WORKE WAITING", registration.waiting);
      },
    });
  }, []);

  const reloadPage = () => {
    console.log("SHOULD RELOAD PAGE", waitingWorker);
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    // fetch("www.example.org", { method: "POST" });
    window.location.reload();
  };
  // {showReload}

  return (
    <Router>
      {showReload && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={showReload}
          onClose={() => setShowReload(false)}
          message="Strona zostala zaktualizowana. Kliknij aby zobaczyc nowszą wersję."
          action={
            <React.Fragment>
              <Button color="secondary" size="small" onClick={reloadPage}>
                UPDATE
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowReload(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Navigation />
        <AppRoute />
        <Footer />
      </div>
    </Router>
  );
}

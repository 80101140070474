import React from 'react';
import EventCard from '../EventCard';
import './UpcomingEvents.scss';
import SmallLink from 'components/ui/SmallLink';
import SectionTitle from 'components/ui/SectionTitle';
import { getFutureEvents } from 'utils';

export default function UpcomingEvents({ events }) {
  const upcoming = getFutureEvents(events)?.slice(0, 3);

  return (
    <section className='upcoming-events'>
      <SectionTitle title='Nadchodzące wydarzenia' />

      <ul className='upcoming-events__list'>
        {upcoming?.map((event) => (
          <li key={event.id} className='upcoming-events__item'>
            <EventCard event={event} />
          </li>
        ))}
      </ul>
      <div className='upcoming-events__more-link'>
        <SmallLink to='/wydarzenia'>Zobacz wszystkie</SmallLink>
      </div>
    </section>
  );
}

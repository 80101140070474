import { format } from "date-fns";

export function ddMMMyyyy(date) {
  return format(new Date(date), "dd MMM yyyy");
}
export function dateWithTime(date) {
  if (!date) {
    return '';
  }
  return format(new Date(date), "dd MMM yyyy  k:mm");
}
export function time(date) {
  return format(new Date(date), "k:mm");
}

export function getFutureEvents(events): any[] | null {
  if (!events) {
    return null;
  }
  const reverseEventsArr = [...events].reverse();
  const upcoming = reverseEventsArr?.filter(
    (item, i) => new Date(`${item.start_time}`).getTime() > new Date().getTime()
  );
  return upcoming;
}

export function replaceURLWithHTMLLinks(text: string) {
  var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(exp, "<a href='$1'>$1</a>");
}

import { Card, CardContent, Typography, Divider } from "@material-ui/core";
import React, { useState } from "react";
import { ModalWithAppImage } from "./ModalWithAppImage/ModalWithAppImage";
import { ModalWihtChallenges } from "./ModalWithChallenges/ModalWithChallenges";
import { CardTitle } from "./ui/CardTitle";
import { LinkStyle } from "./ui/LinkStyle";
import { Spacing } from "./ui/Spacing";
import installAndroidImage from "assets/androidInstallApp.png";
import iosMenuImage from "assets/iosMenu.png";
// import iosMenuImage from '../assets/andriodInstallApp.png';
import iosInstallAppImage from "assets/iosInstallApp.png";
import wielkipostPlanImage from "assets/wielkipostPlan.png";

export function WielkiPostInfo() {
  const [isChallengesDialogOpen, setIsChallengesDialogOpen] = useState(false);
  const [isAppImageDialoOpen, setIsAppImageDialogOpen] = useState(false);
  return (
    <>
      <Spacing vertical={4}>
        <Card>
          <CardContent>
            <CardTitle title="O co chodzi?" />
            <Typography>
              MSJ Glasgow zaprasza wszystkich chętnych do wzięcia udziału w
              wyjątkowym ćwiczeniu duchowym przez okres Wielkiego Postu.
              <br />
              Zapraszamy Cię byś stoczył/a walkę z wygodami tego świata poprzez
              poddanie się wskazanej ascezie, trwając na codziennym rozważaniu
              Pisma Świętego z przewodnikiem duchowym oraz poświęcając Bogu 15
              minut dziennie na pogłębienie z Nim relacji. Jest to także
              ćwiczenie w samodyscyplinie w którym tylko Ty i Bóg będziecie
              wiedzieć jak sumiennie podejmujesz jego trud.
            </Typography>
            <Divider
              style={{
                margin: "30px 0px",
              }}
            />
            <CardTitle title="Zasady uczestnictwa" />
            <Typography>
              Uczestnik tego ćwiczenia będzie codziennie wchodził na stronę
              msjglasgow.org/wielkipost aby zaznaczyć wykonanie danej części
              ćwiczenia duchowego lub sprawdzał to na wersji papierowej{" "}
              <LinkStyle bold href={wielkipostPlanImage} download>
                tutaj
              </LinkStyle>
              .
            </Typography>
            <Divider
              style={{
                margin: "30px 0px",
              }}
            />
            <CardTitle title="Asceza" />
            <Typography>
              W każdym dniu tygodnia będzie wyznaczone inne zadanie ascetyczne.
              Spis zadań oraz krótki ich opis można znaleźć{" "}
              <LinkStyle bold onClick={() => setIsChallengesDialogOpen(true)}>
                tutaj.
              </LinkStyle>
            </Typography>
            <Divider
              style={{
                margin: "30px 0px",
              }}
            />

            <CardTitle title="Rozważanie Pisma Świętego" />
            <Typography>
              Codziennie o 6 rano przewodnik duchowy będzie rozważał czytanie z
              dnia na facebookowym koncie{" "}
              <LinkStyle
                bold
                href="https://facebook.com/msjglasgow"
                target="blank"
                rel="noopener noreferrer"
              >
                MSJ Glasgow
              </LinkStyle>
              . Zachęcamy do łączenia się w transmisji na żywo. Na stronie
              znajduje się link do rozważania które można także odsłuchać w
              ciągu dnia.
            </Typography>
            <Divider
              style={{
                margin: "30px 0px",
              }}
            />

            <CardTitle title="Medytacja" />
            <Typography>
              Ostatnią częścią tego ćwiczenia jest 15 minutowa medytacja
              rozważań z dnia z których co najmniej 10 minut odbywamy w postawie
              milczącej wsłuchując się w głos Pana. Wypowiedz wojnę duchowi tego
              świata w Wielkim Poście i daj się poprowadzić Jezusowi do
              zwycięstwa.
            </Typography>
            <Divider
              style={{
                margin: "30px 0px",
              }}
            />
            <CardTitle
              title="Co jeśli nie moge wypełnić jakiejś ascezy?"
              fontSize="small"
            />
            <Typography>
              Jeżeli z powodów zdrowotnych nie można wypełnić danej ascezy, lub
              wykonanie jej byłoby nierozsądne zalecamy wybranie innej lub
              podjęcie łagodniejszej wersji danej ascezy. Np. jeżeli nie można
              wziąć całego zimnego prysznica, można wziąć troszke chłodniejszy
              na koniec.
            </Typography>
            <Divider
              style={{
                margin: "30px 0px",
              }}
            />
            <CardTitle title="Sprawy techniczne" fontSize="small" />
            <Typography>
              W pierwszy dzień Wielkiego Postu na tej stronie pojawi sie
              aplikacja do odznaczania postępu ćwieczeń duchowych na dany dzień,
              czytanie z dnia i video z rozważaniem księdza. Będzie to wygladać{" "}
              <LinkStyle bold onClick={() => setIsAppImageDialogOpen(true)}>
                tak.
              </LinkStyle>
              <Spacing vertical={2}>
                Ikonke aplikacji można dodać do ekranu głównego na telefonie, by
                mieć do niej łatwiejszy dostęp.
              </Spacing>
              <Spacing vertical={2}>
                <strong>Android</strong>
              </Spacing>
              W górnym prawym rogu (trzy kropeczki) menu jest opcja do
              'zainstalowania' tej aplikacji.
              <img src={installAndroidImage} alt="" width="320px" />
              <Spacing vertical={2}>
                <strong>IPhone</strong>
              </Spacing>
              Na dole jest ikona menu. Po jej otwarciu dostepna jest opjca aby
              dodać aplikacje do ekranu głównego.
              <div
                style={{
                  display: "flex",
                  flexFlow: "wrap",
                  justifyContent: "space-around",
                }}
              >
                <img src={iosMenuImage} alt="" width="320px" />
                <img src={iosInstallAppImage} alt="" width="320px" />
              </div>
            </Typography>
          </CardContent>
        </Card>
      </Spacing>
      <ModalWihtChallenges
        isOpen={isChallengesDialogOpen}
        handleDialogClose={() => setIsChallengesDialogOpen(false)}
      />
      <ModalWithAppImage
        isOpen={isAppImageDialoOpen}
        handleDialogClose={() => setIsAppImageDialogOpen(false)}
      />
    </>
  );
}

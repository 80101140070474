import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Calendar from 'react-calendar';
import CalendarTile from 'components/Calendar/CalendarTile';
import { fetchEvents } from '../redux/eventsSlice';
import EventDetailsDialog from 'components/EventDetailsDialog';
import SectionTitle from 'components/ui/SectionTitle';
import './Calendar.scss';

export default function PostsContainer() {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [eventInfo, setEventInfo] = useState({});
  const eventsList = useSelector((state) => state.events.list?.data);
  const dispatch = useDispatch();

  const [events, setEvents] = useState([]);
  // const [events1, setEvents1] = useState([]);
  // const [formattedDates, setFormattedDates] = useState([]);
  // const [date, setDate] = useState(null);

  useEffect(() => {
    !eventsList && dispatch(fetchEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (eventsList) {
      const formattedEvents = eventsList.map((event) =>
        event.start_time
          ? {
              ...event,
              start_time: format(new Date(event.start_time), 'dd MMM yyyy'),
            }
          : event
      );
      setEvents(formattedEvents);
    }
  }, [eventsList]);

  // function handleDateChange(d) {
  // 	setDate(d);
  // }

  // function checkIfIsEventDay(date) {
  // 	//  format(props.date, 'dd MMM yyyy') === format(date, 'dd MMM yyyy')
  // 	return events.some(d => d.start_time === format(date, 'dd MMM yyyy'));
  // }

  function eventDay(date: number | Date): any {
    const isEvent = events.find(
      (d: any) => d.start_time === format(date, 'dd MMM yyyy')
    );
    return isEvent;
  }

  function getCalendarTileClassName(date: number | Date) {
    let tileClassName = 'calendar-tile';

    if (eventDay(date)) {
      tileClassName += ' calendar-tile--has-event';
    }
    return tileClassName;
  }

  return (
    <section>
      <SectionTitle title="Kalendarz" />
      <div>
        {!!events.length && (
          <Calendar
            className="calendar"
            // onChange={handleDateChange}
            value={new Date()}
            tileContent={(props) => {
              const event = eventDay(props.date);
              return (
                event ? (
                  <CalendarTile
                    event={event}
                    onClick={() => {
                      setIsOpenDialog(true);
                      setEventInfo(event);
                    }}
                  />
                ) : null
              );
            }}
            tileClassName={(props) => getCalendarTileClassName(props.date)}
          />
        )}
      </div>
      <EventDetailsDialog
        open={isOpenDialog}
        handleDialogClose={() => setIsOpenDialog(false)}
        event={eventInfo}
      />
    </section>
  );
}

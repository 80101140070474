//https://www.paulus.org.pl/czytania?data=2021-2-17

export const czytania = [
  //1
  [
    "  EWANGELIA (Mt 6, 1-6. 16-18) ",
    "Ojciec twój, który widzi w ukryciu, odda tobie",

    "Słowa Ewangelii według Świętego Mateusza ",
    `
    Jezus powiedział do swoich uczniów: «Strzeżcie się, żebyście uczynków pobożnych nie wykonywali przed ludźmi po to, aby was widzieli; inaczej bowiem nie będziecie mieli nagrody u Ojca waszego, który jest w niebie.
    Kiedy więc dajesz jałmużnę, nie trąb przed sobą, jak obłudnicy czynią w synagogach i na ulicach, aby ich ludzie chwalili. Zaprawdę, powiadam wam: ci otrzymali już swoją nagrodę. Kiedy zaś ty dajesz jałmużnę, niech nie wie lewa twoja ręka, co czyni prawa, aby twoja jałmużna pozostała w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.
    Gdy się modlicie, nie bądźcie jak obłudnicy. Oni to lubią w synagogach i na rogach ulic wystawać i modlić się, żeby się ludziom pokazać. Zaprawdę, powiadam wam: otrzymali już swoją nagrodę. Ty zaś, gdy chcesz się modlić, wejdź do swej izdebki, zamknij drzwi i módl się do Ojca twego, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie.
    Kiedy pościcie, nie bądźcie posępni jak obłudnicy. Przybierają oni wygląd ponury, aby pokazać ludziom, że poszczą. Zaprawdę, powiadam wam, już odebrali swoją nagrodę. Ty zaś, gdy pościsz, namaść sobie głowę i obmyj twarz, aby nie ludziom pokazać, że pościsz, ale Ojcu twemu, który jest w ukryciu. A Ojciec twój, który widzi w ukryciu, odda tobie».
    
    Oto słowo Pańskie.`,
  ],
  //2
  [
    "EWANGELIA (Łk 9, 22-25) ",
    " Kto straci swoje życie z mego powodu, ten je zachowa ",

    " Słowa Ewangelii według Świętego Łukasza ",

    `
    Jezus powiedział do swoich uczniów: «Syn Człowieczy musi wiele wycierpieć: będzie odrzucony przez starszyznę, arcykapłanów i uczonych w Piśmie; zostanie zabity, a trzeciego dnia zmartwychwstanie».
    Potem mówił do wszystkich: «Jeśli ktoś chce iść za Mną, niech się zaprze samego siebie, niech co dnia bierze krzyż swój i niech Mnie naśladuje. Bo kto chce zachować swoje życie, straci je, a kto straci swe życie z mego powodu, ten je zachowa. Bo cóż za korzyść dla człowieka, jeśli cały świat zyska, a siebie zatraci lub szkodę poniesie?».
  
    Oto słowo Pańskie.
  `,
  ],
  //3
  [
    `
    EWANGELIA (Mt 9, 14-15)
  `,

    `
  Kiedy zabiorą im oblubieńca, wtedy będą pościć
  `,

    `
  Słowa Ewangelii według Świętego Mateusza
  `,

    `
  Po powrocie Jezusa z krainy Gadareńczyków podeszli do Niego uczniowie Jana i zapytali: «Dlaczego my i faryzeusze dużo pościmy, Twoi zaś uczniowie nie poszczą?».
  Jezus im rzekł: «Czy goście weselni mogą się smucić, dopóki pan młody jest z nimi? Lecz przyjdzie czas, kiedy zabiorą im pana młodego, a wtedy będą pościć».
  
  Oto słowo Pańskie.
  `,
  ],
  //4
  [
    `
    EWANGELIA (Łk 5, 27-32)
  `,

    `
    Nie przyszedłem wezwać do nawrócenia sprawiedliwych, lecz grzeszników
  `,

    `
    Słowa Ewangelii według Świętego Łukasza
  `,

    `
    Jezus zobaczył celnika, imieniem Lewi, siedzącego na komorze celnej. Rzekł do niego: «Pójdź za Mną!». On zostawił wszystko, wstał i z Nim poszedł.
    Potem Lewi wydał dla Niego wielkie przyjęcie u siebie w domu; a był spory tłum celników oraz innych ludzi, którzy zasiadali z nimi do stołu. Na to szemrali faryzeusze i uczeni ich w Piśmie, mówiąc do Jego uczniów: «Dlaczego jecie i pijecie z celnikami i grzesznikami?».
    Lecz Jezus im odpowiedział: «Nie potrzebują lekarza zdrowi, ale ci, którzy się źle mają. Nie przyszedłem powołać do nawrócenia się sprawiedliwych, lecz grzeszników».
    
    Oto słowo Pańskie.
  `,
  ],
  //5
  [
    `
  EWANGELIA (Mk 1, 12-15)
  `,

    `
  Jezus był kuszony przez Szatana, aniołowie zaś Mu usługiwali
  `,

    `
  Słowa Ewangelii według Świętego Marka
  `,

    `
  Duch wyprowadził Jezusa na pustynię. A przebywał na pustyni czterdzieści dni, kuszony przez Szatana, i był ze zwierzętami, aniołowie zaś Mu służyli.
  Gdy Jan został uwięziony, Jezus przyszedł do Galilei i głosił Ewangelię Bożą. Mówił: «Czas się wypełnił i bliskie jest królestwo Boże. Nawracajcie się i wierzcie w Ewangelię!».
  
  Oto słowo Pańskie.
  `,
  ],
  //6
  [
    `
  EWANGELIA  (Mt 16,13-19)
  `,

    `
  Tobie dam klucze królestwa niebieskiego
  `,

    `
  Słowa Ewangelii według świętego Mateusza.
  `,

    `
  Gdy Jezus przyszedł w okolice Cezarei Filipowej, pytał swych uczniów: „Za kogo ludzie uważają Syna Człowieczego?”
  A oni odpowiedzieli: „Jedni za Jana Chrzciciela, inni za Eliasza, jeszcze inni za Jeremiasza albo za jednego z proroków”.
  Jezus zapytał ich: „A wy za kogo Mnie uważacie?”
  Odpowiedział Szymon Piotr: „Ty jesteś Mesjasz, Syn Boga żywego”.
  Na to Jezus mu rzekł: „Błogosławiony jesteś, Szymonie, synu Jony. Albowiem ciało i krew nie objawiły ci tego, lecz Ojciec mój, który jest w niebie. Otóż i Ja tobie powiadam: Ty jesteś Piotr – Opoka, i na tej opoce zbuduję mój Kościół, a bramy piekielne go nie przemogą. I tobie dam klucze królestwa niebieskiego; cokolwiek zwiążesz na ziemi, będzie związane w niebie, a co rozwiążesz na ziemi, będzie rozwiązane w niebie”.
  Oto słowo Pańskie.
  `,
  ],
  //7
  [
    `EWANGELIA  (Mt 6,7-15)
  `,
    `Jezus uczy, jak się modlić
  `,
    `Słowa Ewangelii według świętego Mateusza.
  `,

    `Jezus powiedział do swoich uczniów:
  „Na modlitwie nie bądźcie gadatliwi jak poganie. Oni myślą, że przez wzgląd na swe wielomówstwo będą wysłuchani. Nie bądźcie podobni do nich. Albowiem wie Ojciec wasz, czego wam potrzeba, wpierw zanim Go poprosicie.
  Wy zatem tak się módlcie:
  Ojcze nasz, któryś jest w niebie: święć się imię Twoje, przyjdź królestwo Twoje, bądź wola Twoja jako w niebie, tak i na ziemi. Chleba naszego powszedniego daj nam dzisiaj; i odpuść nam nasze winy, jako i my odpuszczamy naszym winowajcom; i nie dopuść, abyśmy ulegli pokusie, ale nas zbaw ode złego.
  Jeśli bowiem przebaczycie ludziom ich przewinienia, i wam przebaczy Ojciec wasz niebieski. Lecz jeśli nie przebaczycie ludziom, i Ojciec wasz nie przebaczy wam waszych przewinień”.
  
  Oto słowo Pańskie.`,
  ],
  //8 24/2
  [
    `  EWANGELIA  (Łk 11,29-32)
  `,
    `
    Znak proroka Jonasza: śmierć i zmartwychwstanie
  `,

    `
    Słowa Ewangelii według świętego Łukasza.
  `,
    `
    Gdy tłumy się gromadziły, Jezus zaczął mówić:
    „To plemię jest plemieniem przewrotnym. Żąda znaku, ale żaden znak nie będzie mu dany, prócz znaku Jonasza. Jak bowiem Jonasz był znakiem dla mieszkańców Niniwy, tak będzie Syn Człowieczy dla tego plemienia.
    Królowa z Południa powstanie na sądzie przeciw ludziom tego plemienia i potępi ich; ponieważ ona przybyła z krańców ziemi słuchać mądrości Salomona, a oto tu jest coś więcej niż Salomon.
    Ludzie z Niniwy powstaną na sądzie przeciw temu plemieniu i potępią je; ponieważ oni dzięki nawoływaniu Jonasza się nawrócili, a oto tu jest coś więcej niż Jonasz”.
    Oto słowo Pańskie.`,
  ],
  //9 25/2
  [
    ` EWANGELIA  (Mt 7,7-12) `,
    ` Kto prosi, otrzymuje `,
    ` Słowa Ewangelii według świętego Mateusza. `,
    `
  Jezus powiedział do swoich uczniów:
  „Proście, a będzie wam dane; szukajcie, a znajdziecie; kołaczcie, a otworzą wam. Albowiem każdy, kto prosi, otrzymuje; kto szuka, znajduje; a kołaczącemu otworzą.
  Gdy którego z was syn prosi o chleb, czy jest taki, który poda mu kamień? Albo gdy prosi o rybę, czy poda mu węża? Jeśli więc wy, choć źli jesteście, umiecie dawać dobre dary swoim dzieciom, o ileż bardziej Ojciec wasz, który jest w niebie, da to co dobre tym, którzy Go proszą.
  Wszystko więc, co byście chcieli, żeby wam ludzie czynili, i wy im czyńcie. Albowiem na tym polega Prawo i Prorocy”.
  Oto słowo Pańskie.
  `,
  ],
  //10 26/2
  [
    `
        EWANGELIA (Mt 5,20-26)
`,

    `
Pojednaj się najpierw z bratem
`,

    `
Słowa Ewangelii według świętego Mateusza.
`,

    `
Jezus powiedział do swoich uczniów:
„Jeśli wasza sprawiedliwość nie będzie większa niż uczonych w Piśmie i faryzeuszów, nie wejdziecie do królestwa niebieskiego.
Słyszeliście, że powiedziano przodkom: «Nie zabijaj»; a kto by się dopuścił zabójstwa, podlega sądowi. A Ja wam powiadam: Każdy, kto się gniewa na swego brata, podlega sądowi. A kto by rzekł swemu bratu «Raka», podlega Wysokiej Radzie. A kto by mu rzekł «Bezbożniku», podlega karze piekła ognistego.
Jeśli więc przyniesiesz dar swój przed ołtarz i tam wspomnisz, że brat twój ma coś przeciw tobie, zostaw tam dar swój przed ołtarzem, a najpierw idź i pojednaj się z bratem swoim. Potem przyjdź i ofiaruj dar swój.
Pogódź się ze swoim przeciwnikiem szybko, dopóki jesteś z nim w drodze, by cię przeciwnik nie podał sędziemu, a sędzia dozorcy, i aby nie wtrącono cię do więzienia. Zaprawdę powiadam ci: nie wyjdziesz stamtąd, aż zwrócisz ostatni grosz”.
Oto słowo Pańskie.
`,
  ],
  // 11 27/2
  [
    `
    EWANGELIA  (Mt 5,43-48)
`,

    `
    Bądźcie doskonali, jak doskonały jest wasz Ojciec niebieski
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    Jezus powiedział do swoich uczniów:
    „Słyszeliście, że powiedziano: «Będziesz miłował swego bliźniego, a nieprzyjaciela swego będziesz nienawidził».
    A Ja wam powiadam: Miłujcie waszych nieprzyjaciół i módlcie się za tych, którzy was prześladują; tak będziecie synami Ojca waszego, który jest w niebie; ponieważ On sprawia, że słońce Jego wschodzi nad złymi i nad dobrymi, i On zsyła deszcz na sprawiedliwych i niesprawiedliwych.
    Jeśli bowiem miłujecie tych, którzy was miłują, cóż za nagrodę mieć będziecie? Czyż i celnicy tego nie czynią? I jeśli pozdrawiacie tylko swych braci, cóż szczególnego czynicie? Czyż i poganie tego nie czynią?
    Bądźcie więc wy doskonali, jak doskonały jest wasz Ojciec niebieski”.
     
    Oto słowo Pańskie.
`,
  ],
  //12
  [
    `
    EWANGELIA (Mk 9,2-10)
`,

    `
    Przemienienie Pańskie
`,

    `
    Słowa Ewangelii według świętego Marka.
`,

    `
    Jezus wziął z sobą Piotra, Jakuba i Jana i zaprowadził ich samych osobno na górę wysoką. Tam przemienił się wobec nich. Jego odzienie stało się lśniąco białe tak, jak żaden wytwórca sukna na ziemi wybielić nie zdoła. I ukazał się im Eliasz z Mojżeszem, którzy rozmawiali z Jezusem.
    Wtedy Piotr rzekł do Jezusa: „Rabbi, dobrze, że tu jesteśmy; postawimy trzy namioty: jeden dla Ciebie, jeden dla Mojżesza i jeden dla Eliasza”. Nie wiedział bowiem, co należy mówić, tak byli przestraszeni.
    I zjawił się obłok, osłaniający ich, a z obłoku odezwał się głos: „To jest mój Syn umiłowany, Jego słuchajcie”. I zaraz potem, gdy się rozejrzeli, nikogo już nie widzieli przy sobie, tylko samego Jezusa.
    A gdy schodzili z góry, przykazał im, aby nikomu nie rozpowiadali o tym, co widzieli, zanim Syn Człowieczy nie powstanie z martwych. Zachowali to polecenie, rozprawiając tylko między sobą, co znaczy powstać z martwych.
    
    Oto słowo Pańskie.
`,
  ],
  //13
  [
    `
    EWANGELIA  (Łk 6,36-38)
`,

    `
    Odpuszczajcie, a będzie wam odpuszczone
`,

    `
    Słowa Ewangelii według świętego Łukasza.
`,

    `
    Jezus powiedział do swoich uczniów:
    „Bądźcie miłosierni, jak Ojciec wasz jest miłosierny. Nie sądźcie, a nie będziecie sądzeni; nie potępiajcie, a nie będziecie potępieni; odpuszczajcie, a będzie wam odpuszczone.
    Dawajcie, a będzie wam dane: miarą dobrą, natłoczoną, utrzęsioną i opływającą wsypią w zanadrze wasze. Odmierzą wam bowiem taką miarą, jaką wy mierzycie”.
    Oto słowo Pańskie.
`,
  ],
  //14 2/3
  [
    `
    EWANGELIA  (Mt 23,1-12)
`,

    `
    Strzeżcie się pychy i obłudy
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    Jezus przemówił do tłumów i do swych uczniów tymi słowami:
    „Na katedrze Mojżesza zasiedli uczeni w Piśmie i faryzeusze. Czyńcie więc i zachowujcie wszystko, co wam polecą, lecz uczynków ich nie naśladujcie. Mówią bowiem, ale sami nie czynią. Wiążą ciężary wielkie i nie do uniesienia i kładą je ludziom na ramiona, lecz sami palcem ruszyć ich nie chcą.
    Wszystkie swe uczynki spełniają w tym celu, żeby się ludziom pokazać. Rozszerzają swoje filakterie i wydłużają frędzle u płaszczów. Lubią zaszczytne miejsce na ucztach i pierwsze krzesła w synagogach. Chcą, by ich pozdrawiano na rynkach i żeby ludzie nazywali ich Rabbi.
    Wy zaś nie pozwalajcie nazywać się Rabbi, albowiem jeden jest wasz Nauczyciel, a wy wszyscy braćmi jesteście. Nikogo też na ziemi nie nazywajcie waszym ojcem; jeden bowiem jest Ojciec wasz, Ten, który jest w niebie. Nie chciejcie również, żeby was nazywano mistrzami, bo tylko jeden jest wasz Mistrz, Chrystus.
    Największy z was niech będzie waszym sługą. Kto się wywyższa, będzie poniżony, a kto się poniża, będzie wywyższony”.
    Oto słowo Pańskie.
`,
  ],
  //15 /3/
  [
    `
    EWANGELIA  (Mt 20,17-28)
`,

    `
    Syn Człowieczy zostanie wydany na ukrzyżowanie
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    
    Mając udać się do Jerozolimy, Jezus wziął osobno Dwunastu i w drodze rzekł do nich: „Oto idziemy do Jerozolimy: tam Syn Człowieczy zostanie wydany arcykapłanom i uczonym w Piśmie. Oni skażą Go na śmierć i wydadzą Go poganom na wyszydzenie, ubiczowanie i ukrzyżowanie; a trzeciego dnia zmartwychwstanie”.
    Wtedy podeszła do Niego matka synów Zebedeusza ze swoimi synami i upadając Mu do nóg, o coś Go prosiła.
    On ją zapytał: „Czego pragniesz?”
    Rzekła Mu: „Po wiedz, żeby ci dwaj moi synowie zasiedli w Twoim królestwie jeden po prawej, a drugi po lewej Twej stronie”.
    Odpowiadając Jezus rzekł: „Nie wiecie, o co prosicie. Czy możecie pić kielich, który Ja mam pić?”
    Odpowiedzieli Mu: „Możemy”.
    On rzekł do nich: „Kielich mój pić będziecie. Nie do Mnie jednak należy dać miejsce po mojej stronie prawej i lewej, ale dostanie się ono tym, dla których mój Ojciec je przygotował”.
    Gdy dziesięciu to usłyszało, oburzyli się na tych dwóch braci. A Jezus przywołał ich do siebie i rzekł: „Wiecie, że władcy narodów uciskają je, a wielcy dają im odczuć swą władzę. Nie tak będzie u was. Lecz kto by między wami chciał stać się wielkim, niech będzie waszym sługą. A kto by chciał być pierwszym między wami, niech będzie waszym niewolnikiem.
    Na wzór Syna Człowieczego, który nie przyszedł, aby Mu służono, lecz aby służyć i dać swoje życie na okup za wielu”.
    Oto słowo Pańskie.
`,
  ],
  //16 4/3
  ["", "", "", ""],
  //17 /5/3
  [
    `
    EWANGELIA  (Mt 21,33-43.45-46)
`,

    `
    Przypowieść o dzierżawcach winnicy
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    Jezus powiedział do arcykapłanów i starszych ludu:
    „Posłuchajcie innej przypowieści: Był pewien gospodarz, który założył winnicę. Otoczył ją murem, wykopał w niej tłocznię, zbudował wieżę, w końcu oddał ją w dzierżawę rolnikom i wyjechał.
    Gdy nadszedł czas zbiorów, posłał swoje sługi do rolników, by odebrali plon jemu należny. Ale rolnicy chwycili jego sługi i jednego obili, drugiego zabili, trzeciego zaś kamieniami obrzucili. Wtedy posłał inne sługi, więcej niż za pierwszym razem, lecz i z nimi tak samo postąpili. W końcu posłał do nich swego syna, tak sobie myśląc: «Uszanują mojego syna».
    Lecz rolnicy zobaczywszy syna mówili do siebie: «To jest dziedzic; chodźcie, zabijmy go, a posiądziemy jego dziedzictwo». Chwyciwszy go, wyrzucili z winnicy i zabili. Kiedy więc właściciel winnicy przyjdzie, co uczyni z owymi rolnikami?”
    Rzekli Mu: „Nędzników marnie wytraci, a winnicę odda w dzierżawę innym rolnikom, takim, którzy mu będą oddawali plon we właściwej porze”.
    Jezus im rzekł: „Czy nigdy nie czytaliście w Piśmie: «Kamień, który odrzucili budujący, ten stał się kamieniem węgielnym. Pan to sprawił i jest cudem w naszych oczach». Dlatego powiadam wam: Królestwo Boże będzie wam zabrane, a dane narodowi, który wyda jego owoce”.
    Arcykapłani i faryzeusze słuchając Jego przypowieści poznali, że o nich mówi. Toteż starali się Go ująć, lecz bali się tłumów, ponieważ Go miały za proroka.
    
    Oto słowo Pańskie.
`,
  ],
  //18 6/3
  [
    `
    EWANGELIA  (Łk 15,1-3.11-32)
`,

    `
    Przypowieść o synu marnotrawnym
`,

    `
    Słowa Ewangelii według świętego Łukasza.
`,

    `
    W owym czasie zbliżali się do Jezusa wszyscy celnicy i grzesznicy, aby Go słuchać. Na to szemrali faryzeusze i uczeni w Piśmie: „Ten przyjmuje grzeszników i jada z nimi”.
    Opowiedział im wtedy następującą przypowieść:
    „Pewien człowiek miał dwóch synów. Młodszy z nich rzekł do ojca: «Ojcze, daj mi część majątku, która na mnie przypada». Podzielił więc majątek między nich. Niedługo potem młodszy syn, zebrawszy wszystko, odjechał w dalekie strony i tam roztrwonił swój majątek, żyjąc rozrzutnie.
    A gdy wszystko wydał, nastał ciężki głód w owej krainie i on sam zaczął cierpieć niedostatek. Poszedł i przystał do jednego z obywateli owej krainy, a ten posłał go na swoje pola, żeby pasł świnie. Pragnął on napełnić swój żołądek strąkami, które jadały świnie, lecz nikt mu ich nie dawał.
    Wtedy zastanowił się i rzekł: «Iluż to najemników mojego ojca ma pod dostatkiem chleba, a ja tu z głodu ginę. Zabiorę się i pójdę do mego ojca, i powiem mu: Ojcze, zgrzeszyłem przeciw Bogu i względem ciebie; już nie jestem godzien nazywać się twoim synem: uczyń mię choćby jednym z najemników». Wybrał się więc i poszedł do swojego ojca.
    A gdy był jeszcze daleko, ujrzał go jego ojciec i wzruszył się głęboko; wybiegł naprzeciw niego, rzucił mu się na szyję i ucałował go. A syn rzekł do niego: «Ojcze, zgrzeszyłem przeciw Bogu i względem ciebie, już nie jestem godzien nazywać się twoim synem».
    Lecz ojciec rzekł do swoich sług: «Przynieście szybko najlepszą suknię i ubierzcie go; dajcie mu też pierścień na rękę i sandały na nogi. Przyprowadźcie utuczone cielę i zabijcie: będziemy ucztować i bawić się, ponieważ ten mój syn był umarły, a znów ożył; zaginął, a odnalazł się». I zaczęli się bawić.
    Tymczasem starszy jego syn przebywał na polu. Gdy wracał i był blisko domu, usłyszał muzykę i tańce. Przywołał jednego ze sług i pytał go, co to znaczy. Ten mu rzekł: «Twój brat powrócił, a ojciec twój kazał zabić utuczone cielę, ponieważ odzyskał go zdrowego».
    Na to rozgniewał się i nie chciał wejść; wtedy ojciec jego wyszedł i tłumaczył mu. Lecz on odpowiedział ojcu: «Oto tyle lat ci służę i nigdy nie przekroczyłem twojego rozkazu; ale mnie nie dałeś nigdy koźlęcia, żebym się zabawił z przyjaciółmi. Skoro jednak wrócił ten syn twój, który roztrwonił twój majątek z nierządnicami, kazałeś zabić dla niego utuczone cielę».
    Lecz on mu odpowiedział: «Moje dziecko, ty zawsze jesteś przy mnie i wszystko moje do ciebie należy. A trzeba się weselić i cieszyć z tego, że ten brat twój był umarły, a znów ożył; zaginął, a odnalazł się»”.
    
    Oto słowo Pańskie.
`,
  ],
  //19 7/3
  [
    `
    EWANGELIA (J 2,13-25)
`,

    `
    Zapowiedź męki i zmartwychwstania
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Zbliżała się pora Paschy żydowskiej i Jezus udał się do Jerozolimy. W świątyni napotkał tych, którzy sprzedawali woły, baranki i gołębie oraz siedzących za stołami bankierów. Wówczas sporządziwszy sobie bicz ze sznurków, powyrzucał wszystkich ze świątyni, także baranki i woły, porozrzucał monety bankierów, a stoły powywracał. Do tych zaś, którzy sprzedawali gołębie, rzekł: „Weźcie to stąd, a nie róbcie z domu Ojca mego targowiska”. Uczniowie Jego przypomnieli sobie, że napisano: „Gorliwość o dom Twój pożera Mnie”.
    W odpowiedzi zaś na to Żydzi rzekli do Niego: „Jakim znakiem wykażesz się wobec nas, skoro takie rzeczy czynisz?” Jezus dał im taką odpowiedź: „Zburzcie tę świątynię, a Ja w trzech dniach wzniosę ją na nowo”.
    Powiedzieli do Niego Żydzi: „Czterdzieści sześć lat budowano tę świątynię, a Ty ją wzniesiesz w przeciągu trzech dni?”
    On zaś mówił o świątyni swego ciała. Gdy więc zmartwychwstał, przypomnieli sobie uczniowie Jego, że to powiedział, i uwierzyli Pismu i słowu, które wyrzekł Jezus.
    Kiedy zaś przebywał w Jerozolimie w czasie Paschy, w dniu świątecznym, wielu uwierzyło w imię Jego, widząc znaki, które czynił. Jezus natomiast nie zwierzał się im, bo dobrze wszystkich znał i nie potrzebował niczyjego świadectwa o człowieku. Sam bowiem wiedział, co się kryje w człowieku.
    
    Oto słowo Pańskie.
`,
  ],
  //20 8/3
  [
    `
    EWANGELIA  (Łk 4,24-30)
`,

    `
    Jezus został posłany do wszystkich ludów
`,

    `
    Słowa Ewangelii według świętego Łukasza.
`,

    `
    Kiedy Jezus przyszedł do Nazaretu, przemówił do ludu w synagodze:
    „Zaprawdę powiadam wam: Żaden prorok nie jest mile widziany w swojej ojczyźnie.
    Naprawdę mówię wam: Wiele wdów było w Izraelu za czasów Eliasza, kiedy niebo pozostawało zamknięte przez trzy lata i sześć miesięcy, tak że wielki głód panował w całym kraju; a Eliasz do żadnej z nich nie został posłany, tylko do owej wdowy w Sarepcie Sydońskiej.
    I wielu trędowatych było w Izraelu za proroka Elizeusza, a żaden z nich nie został oczyszczony, tylko Syryjczyk Naaman”.
    Na te słowa wszyscy w synagodze unieśli się gniewem. Porwali Go z miejsca, wyrzucili Go z miasta i wyprowadzili aż na stok góry, na której ich miasto było zbudowane, aby Go strącić. On jednak przeszedłszy pośród nich oddalił się.
    Oto słowo Pańskie.
`,
  ],
  //21 /9/3
  [
    `
    EWANGELIA  (Mt 18,21-35)
`,

    `
    Przypowieść o niemiłosiernym słudze
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    Piotr zbliżył się do Jezusa i zapytał: „Panie, ile razy mam przebaczyć, jeśli mój brat wykroczy przeciwko mnie? Czy aż siedem razy?”
    Jezus mu odrzekł: „Nie mówię ci, że aż siedem razy, lecz aż siedemdziesiąt siedem razy.
    Dlatego podobne jest królestwo niebieskie do króla, który chciał się rozliczyć ze swymi sługami. Gdy zaczął się rozliczać, przyprowadzono mu jednego, który mu był winien dziesięć tysięcy talentów. Ponieważ nie miał z czego ich oddać, pan kazał sprzedać go razem z żoną, dziećmi i całym jego mieniem, i tak dług oddać.
    Wtedy sługa upadł przed nim i prosił go: «Panie, miej cierpliwość nade mną, a wszystko ci oddam». Pan ulitował się nad tym sługą, uwolnił go i dług mu darował.
    Lecz gdy sługa ów wyszedł, spotkał jednego ze współsług, który mu był winien sto denarów. Chwycił go i zaczął dusić, mówiąc: «Oddaj, coś winien!» Jego współsługa upadł przed nim i prosił go: «Miej cierpliwość nade mną, a oddam tobie». On jednak nie chciał, lecz odszedł i wtrącił go do więzienia, dopóki nie odda długu.
    Współsłudzy jego widząc, co się działo, bardzo się zasmucili. Poszli i opowiedzieli swemu panu wszystko, co zaszło.
    Wtedy pan jego wezwał go przed siebie i rzekł mu: «Sługo niegodziwy, darowałem ci cały ten dług, ponieważ mnie prosiłeś. Czyż więc i ty nie powinieneś był ulitować się nad swym współsługa, jak ja ulitowałem się nad tobą?» I uniesiony gniewem pan jego kazał wydać go katom, dopóki mu całego długu nie odda.
    Podobnie uczyni wam Ojciec mój niebieski, jeżeli każdy z was nie przebaczy z serca swemu bratu”.
    
    Oto słowo Pańskie.
`,
  ],
  //22 /10/3
  [
    `
    EWANGELIA (Mt 5,17-19)
`,

    `
Kto wypełnia przykazania, ten będzie wielki w królestwie niebieskim
`,

    `
Słowa Ewangelii według świętego Mateusza.
`,

    `
Jezus powiedział do swoich uczniów:
„Nie sądźcie, że przyszedłem znieść Prawo albo Proroków. Nie przyszedłem znieść, ale wypełnić. Zaprawdę bowiem powiadam wam: Dopóki niebo i ziemia nie przeminą, ani jedna jota, ani jedna kreska nie zmieni się w Prawie, aż się wszystko spełni.
Ktokolwiek więc zniósłby jedno z tych przykazań, choćby najmniejsze, i uczyłby tak ludzi, ten będzie najmniejszy w królestwie niebieskim. A kto je wypełnia i uczy wypełniać, ten będzie wielki w królestwie niebieskim”.

Oto słowo Pańskie.
`,
  ],
  //23 11/3
  [
    `
    EWANGELIA  (Łk 11,14-23)
`,

    `
    Kto nie jest ze Mną, jest przeciwko Mnie
`,

    `
    Słowa Ewangelii według świętego Łukasza.
`,

    `
    Jezus wyrzucał złego ducha, który był niemy. A gdy zły duch wyszedł, niemy zaczął mówić i tłumy były zdumione. Lecz niektórzy z nich rzekli: „Przez Belzebuba, władcę złych duchów, wyrzuca złe duchy”. Inni zaś, chcąc Go wystawić na próbę, domagali się od Niego znaku z nieba.
    On jednak, znając ich myśli, rzekł do nich: „Każde królestwo wewnętrznie rozdarte pustoszeje i dom na dom się wali. Jeśli więc i szatan sam przeciw sobie wewnętrznie jest rozdwojony, jakże się ostoi jego królestwo? Mówicie bowiem, że Ja przez Belzebuba wyrzucam złe duchy. Lecz jeśli Ja przez Belzebuba wyrzucam złe duchy, to przez kogo je wyrzucają wasi synowie? Dlatego oni będą waszymi sędziami. A jeśli Ja palcem Bożym wyrzucam złe duchy, to istotnie przyszło już do was królestwo Boże.
    Gdy mocarz uzbrojony strzeże swego dworu, bezpieczne jest jego mienie. Lecz gdy mocniejszy od niego nadejdzie i pokona go, zabierze wszystką broń jego, na której polegał, i łupy jego rozda.
    Kto nie jest ze Mną, jest przeciwko Mnie; a kto nie zbiera ze Mną, rozprasza”.
    
    Oto słowo Pańskie.
`,
  ],
  //23 12/3
  [
    `
    EWANGELIA  (Mk 12,28b-34)
`,

    `
    Pierwsze ze wszystkich przykazań
`,

    `
    Słowa Ewangelii według świętego Marka.
`,

    `
    Jeden z uczonych w Piśmie zbliżył się do Jezusa i zapytał Go: „Które jest pierwsze ze wszystkich przykazań?”
    Jezus odpowiedział: „Pierwsze jest: «Słuchaj, Izraelu, Pan, Bóg nasz, Pan jest jedyny. Będziesz miłował Pana, Boga swego, całym swoim sercem, całą swoją duszą, całym swoim umysłem i całą swoją mocą». Drugie jest to: «Będziesz miłował bliźniego swego jak siebie samego». Nie ma innego przykazania większego od tych”.
    Rzekł Mu uczony w Piśmie: „Bardzo dobrze, Nauczycielu, słusznieś powiedział, bo Jeden jest i nie ma innego prócz Niego. Miłować Go całym sercem, całym umysłem i całą mocą i miłować bliźniego jak siebie samego daleko więcej znaczy niż wszystkie całopalenia i ofiary”.
    Jezus widząc, że rozumnie odpowiedział, rzekł do niego: „Niedaleko jesteś od królestwa Bożego”. I nikt już nie odważył się więcej Go pytać.
     
    Oto słowo Pańskie.
`,
  ],
  //24
  [
    `
    EWANGELIA (Łk 18,9-14)
`,

    `
    Przypowieść o faryzeuszu i celniku
`,

    `
    Słowa Ewangelii według świętego Łukasza.
`,

    `
    Jezus powiedział do niektórych, co ufali sobie, że są sprawiedliwi, a innymi gardzili, tę przypowieść:
    „Dwóch ludzi przyszło do świątyni, żeby się modlić, jeden faryzeusz, a drugi celnik. Faryzeusz stanął i tak w duszy się modlił: «Boże, dziękuję Ci, że nie jestem jak inni ludzie, zdziercy, oszuści, cudzołożnicy, albo jak i ten celnik. Zachowuję post dwa razy w tygodniu, daję dziesięcinę ze wszystkiego, co nabywam».
    Natomiast celnik stał z daleka i nie śmiał nawet oczu wznieść ku niebu, lecz bił się w piersi i mówił: «Boże, miej litość dla mnie, grzesznika».
    Powiadam wam: Ten odszedł do domu usprawiedliwiony, nie tamten. Każdy bowiem, kto się wywyższa, będzie poniżony, a kto się uniża, będzie wywyższony”.
    
    Oto słowo Pańskie.
`,
  ],
  //25 14/3
  [
    `
    EWANGELIA (J 3,14-21)
`,

    `
    Bóg posłał swego Syna, aby świat został zbawiony
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus powiedział do Nikodema: „Jak Mojżesz wywyższył węża na pustyni, tak potrzeba, by wywyższono Syna Człowieczego, aby każdy, kto w Niego wierzy, miał życie wieczne.
    Tak bowiem Bóg umiłował świat, że Syna swego Jednorodzonego dał, aby każdy, kto w Niego wierzy, nie zginął, ale miał życie wieczne.
    Albowiem Bóg nie posłał swego Syna na świat po to, aby świat potępił, ale po to, by świat został przez Niego zbawiony.
    Kto wierzy w Niego, nie podlega potępieniu; a kto nie wierzy, już został potępiony, bo nie uwierzył w imię Jednorodzonego Syna Bożego.
    A sąd polega na tym, że światło przyszło na świat, lecz ludzie bardziej umiłowali ciemność aniżeli światło: bo złe były ich uczynki.
    Każdy bowiem, kto się dopuszcza nieprawości, nienawidzi światła i nie zbliża się do światła, aby nie potępiono jego uczynków. Kto spełnia wymagania prawdy, zbliża się do światła, aby się okazało, że jego uczynki są dokonane w Bogu”.
    
    Oto słowo Pańskie.
`,
  ],
  // 26 15/3
  [
    `
    EWANGELIA  (J 4,43-54)
`,

    `
    Uzdrowienie syna urzędnika królewskiego
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus wyszedł z Samarii i udał się do Galilei. Jezus wprawdzie sam stwierdził, że prorok nie doznaje czci we własnej ojczyźnie. Kiedy jednak przybył do Galilei, Galilejczycy przyjęli Go, ponieważ widzieli wszystko, co uczynił w Jerozolimie w czasie świąt. I oni bowiem przybyli na święto.
    Następnie przybył powtórnie do Kany Galilejskiej, gdzie przedtem przemienił wodę w wino. A w Kafarnaum mieszkał pewien urzędnik królewski, którego syn chorował. Usłyszawszy, że Jezus przybył z Judei do Galilei, udał się do Niego z prośbą, aby przyszedł i uzdrowił jego syna: był on bowiem już umierający.
    Jezus rzekł do niego: „Jeżeli znaków i cudów nie zobaczycie, nie uwierzycie”.
    Powiedział do Niego urzędnik królewski: „Panie, przyjdź, zanim umrze moje dziecko”.
    Rzekł do niego Jezus: „Idź, syn twój żyje”. Uwierzył człowiek słowu, które Jezus powiedział do niego, i szedł z powrotem.
    A kiedy był jeszcze w drodze, słudzy wyszli mu naprzeciw, mówiąc, że syn jego żyje. Zapytał ich o godzinę, o której mu się polepszyło. Rzekli mu: „Wczoraj około godziny siódmej opuściła go gorączka”. Poznał więc ojciec, że było to o tej godzinie, o której Jezus rzekł do niego: „Syn twój żyje”. I uwierzył on sam i cała jego rodzina.
    Ten już drugi znak uczynił Jezus od chwili przybycia z Judei do Galilei.
     
    Oto słowo Pańskie.
`,
  ],
  //27 16/3
  [
    `
    EWANGELIA  (J 5,1-3a.5-16)
`,

    `
    Uzdrowienie paralityka w sadzawce Betesda
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Było święto żydowskie i Jezus udał się do Jerozolimy.
    W Jerozolimie zaś znajduje się Sadzawka Owcza, nazwana po hebrajsku Betesda, zaopatrzona w pięć krużganków. Wśród nich leżało mnóstwo chorych: niewidomych, chromych, sparaliżowanych.
    Znajdował się tam pewien człowiek, który już od lat trzydziestu ośmiu cierpiał na swoją chorobę. Gdy Jezus ujrzał go leżącego i poznał, że czeka już długi czas, rzekł do niego: „Czy chcesz stać się zdrowym?”
    Odpowiedział Mu chory: „Panie, nie mam człowieka, aby mnie wprowadził do sadzawki, gdy nastąpi poruszenie wody. Gdy ja sam już dochodzę, inny schodzi przede mną”.
    Rzekł do niego Jezus: „Wstań, weź swoje łoże i chodź”. Natychmiast wyzdrowiał ów człowiek, wziął swoje łoże i chodził.
    Jednakże dnia tego był szabat. Rzekli więc Żydzi do uzdrowionego: „Dziś jest szabat, nie wolno ci nieść twojego łoża”.
    On im odpowiedział: „Ten, który mnie uzdrowił, rzekł do mnie: Weź swoje łoże i chodź”. Pytali go więc: „Cóż to za człowiek ci powiedział: Weź i chodź?” Lecz uzdrowiony nie wiedział, kim On jest; albowiem Jezus odsunął się od tłumu, który był w tym miejscu.
    Potem Jezus znalazł go w świątyni i rzeki do niego: „Oto wyzdrowiałeś. Nie grzesz już więcej, aby ci się coś gorszego nie przydarzyło”. Człowiek ów odszedł i doniósł Żydom, że to Jezus go uzdrowił. I dlatego Żydzi prześladowali Jezusa, że to uczynił w szabat.
    Oto słowo Pańskie.
`,
  ],
  //28 17/3
  [
    `
    EWANGELIA  (J 5,17-30)
`,

    `
    Syn Boży ożywia tych, których chce
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Żydzi prześladowali Jezusa, ponieważ uzdrowił w szabat. Lecz Jezus im odpowiedział: „Ojciec mój działa aż do tej chwili i Ja działam”.
    Dlatego więc usiłowali Żydzi tym bardziej Go zabić, bo nie tylko nie zachował szabatu, ale nadto Boga nazywał swoim Ojcem, czyniąc się równym Bogu.
    W odpowiedzi na to Jezus im mówił: „Zaprawdę, zaprawdę powiadam wam: Syn nie mógłby niczego czynić sam od siebie, gdyby nie widział Ojca czyniącego. Albowiem to samo, co On czyni, podobnie i Syn czyni. Ojciec bowiem miłuje Syna i ukazuje Mu to wszystko, co On sam czyni, i jeszcze większe dzieła ukaże Mu, abyście się dziwili.
    Albowiem jak Ojciec wskrzesza umarłych i ożywia, tak również i Syn ożywia tych, których chce. Ojciec bowiem nie sądzi nikogo, lecz cały sąd przekazał Synowi, aby wszyscy oddawali cześć Synowi, tak jak oddają cześć Ojcu. Kto nie oddaje czci Synowi, nie oddaje czci Ojcu, który Go posłał. Zaprawdę, zaprawdę powiadam wam: Kto słucha słowa mego i wierzy w Tego, który Mnie posłał, ma życie wieczne i nie idzie na sąd, lecz ze śmierci przeszedł do życia.
    Zaprawdę, zaprawdę powiadam wam, że nadchodzi godzina, nawet już jest, kiedy to umarli usłyszą głos Syna Bożego, i ci, którzy usłyszą, żyć będą. Podobnie jak Ojciec ma życie w sobie, tak również dał Synowi: mieć życie w sobie samym. Przekazał Mu władzę wykonywania sądu, ponieważ jest Synem Człowieczym.
    Nie dziwcie się temu! Nadchodzi bowiem godzina, w której wszyscy, którzy spoczywają w grobach, usłyszą głos Jego: a ci, którzy pełnili dobre czyny, pójdą na zmartwychwstanie życia; ci, którzy pełnili złe czyny, na zmartwychwstanie potępienia.
    Ja sam z siebie nic czynić nie mogę. Tak, jak słyszę, sądzę, a sąd mój jest sprawiedliwy; nie szukam bowiem własnej woli, lecz woli Tego, który Mnie posłał”.
    Oto słowo Pańskie.
`,
  ],
  // 29 18/3
  [
    `
    EWANGELIA  (J 5,31-47)
`,

    `
    Nie chcecie przyjść do Mnie, aby mieć życie
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus powiedział do Żydów:
    „Gdybym Ja wydawał świadectwo o sobie samym, sąd mój nie byłby prawdziwy. Jest przecież ktoś inny, kto wydaje sąd o Mnie; a wiem, że sąd, który o Mnie wydaje, jest prawdziwy.
    Wysłaliście poselstwo do Jana i on dał świadectwo prawdzie. Ja nie zważam na świadectwo człowieka, ale mówię to, abyście byli zbawieni. On był lampą, co płonie i świeci, wy zaś chcieliście radować się krótki czas jego światłem.
    Ja mam świadectwo większe od Janowego. Są to dzieła, które Ojciec dał Mi do wykonania; dzieła, które czynię, świadczą o Mnie, że Ojciec Mnie posłał. Ojciec, który Mnie posłał, On dał o Mnie świadectwo. Nigdy nie słyszeliście ani Jego głosu, ani nie widzieliście Jego oblicza; nie macie także słowa Jego, trwającego w was, bo wyście nie uwierzyli w Tego, którego On posłał.
    Badacie Pisma, ponieważ sądzicie, że w nich zawarte jest życie wieczne: to one właśnie dają o Mnie świadectwo. A przecież nie chcecie przyjść do Mnie, aby mieć życie.
    Nie odbieram chwały od ludzi, ale wiem o was, że nie macie w sobie miłości Boga. Przyszedłem w imieniu Ojca mego, a nie przyjęliście Mnie. Gdyby jednak przybył kto inny we własnym imieniu, to byście go przyjęli. Jak możecie uwierzyć, skoro od siebie wzajemnie odbieracie chwałę, a nie szukacie chwały, która pochodzi od samego Boga?
    Nie mniemajcie jednak, że to Ja was oskarżę przed Ojcem. Waszym oskarżycielem jest Mojżesz, w którym wy pokładacie nadzieję. Gdybyście jednak uwierzyli Mojżeszowi, to byście i Mnie uwierzyli. O Mnie bowiem on pisał. Jeżeli jednak jego pismom nie wierzycie, jakże moim słowom będziecie wierzyli?”
    Oto słowo Pańskie.
`,
  ],
  //30 19/3
  [
    `
    EWANGELIA (Mt 1,16.18-21.24a)
`,

    `
    Józef poznaje tajemnicę Wcielenia
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    Jakub był ojcem Józefa, męża Maryi, z której narodził się Jezus, zwany Chrystusem.
    Z narodzeniem Jezusa Chrystusa było tak. Po zaślubinach Matki Jego, Maryi, z Józefem, wpierw nim zamieszkali razem, znalazła się brzemienną za sprawą Ducha Świętego.
    Mąż Jej, Józef, który był człowiekiem prawym i nie chciał narazić Jej na zniesławienie, zamierzał oddalić Ją potajemnie.
    Gdy powziął tę myśl, oto anioł Pański ukazał mu się we śnie i rzekł: „Józefie, synu Dawida, nie bój się wziąć do siebie Maryi, twej Małżonki; albowiem z Ducha Świętego jest to, co się w Niej poczęło. Porodzi Syna, któremu nadasz imię Jezus, On bowiem zbawi swój lud od jego grzechów”.
    Zbudziwszy się ze snu, Józef uczynił tak, jak mu polecił anioł Pański.
    
    Oto słowo Pańskie.
`,
  ],
  //31 /20/3
  [
    `
    EWANGELIA  (J 7,40-53)
`,

    `
    Spory około osoby Jezusa
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Wśród tłumów słuchających Jezusa odezwały się głosy: „Ten prawdziwie jest prorokiem”. Inni mówili: „To jest Mesjasz”. Jeszcze inni mówili: „Czyż Mesjasz przyjdzie z Galilei? Czyż Pismo nie mówi, że Mesjasz będzie pochodził z potomstwa Dawidowego i z miasteczka Betlejem?” I powstało w tłumie rozdwojenie z Jego powodu. Niektórzy chcieli Go nawet pojmać, lecz nikt nie odważył się podnieść na Niego ręki.
    Wrócili więc strażnicy do kapłanów i faryzeuszów, a ci rzekli do nich: „Czemuście Go nie pojmali?”
    Strażnicy odpowiedzieli: „Nigdy jeszcze nikt nie przemawiał tak, jak Ten człowiek przemawia”.
    Odpowiedzieli im faryzeusze: „Czyż i wy daliście się zwieść? Czy ktoś ze zwierzchników lub faryzeuszów uwierzył w Niego? A ten tłum, który nie zna Prawa, jest przeklęty”.
    Odezwał się do nich jeden spośród nich, Nikodem, ten, który przedtem przyszedł do Niego: „Czy Prawo nasze potępia człowieka, zanim go wpierw przesłucha i zbada, co czyni ?”
    Odpowiedzieli mu: „Czy i ty jesteś z Galilei? Zbadaj, zobacz, że żaden prorok nie powstaje z Galilei”.
    I rozeszli się każdy do swego domu.
    Oto słowo Pańskie.
`,
  ],
  //32 21/3
  [
    `
    EWANGELIA (J 12,20-33)
`,

    `
    Ziarno, które wpadłszy w ziemię obumrze, przynosi plon obfity
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Wśród tych, którzy przybyli, aby oddać pokłon Bogu w czasie święta, byli też niektórzy Grecy. Oni więc przystąpili do Filipa, pochodzącego z Betsaidy Galilejskiej, i prosili go, mówiąc: „Panie, chcemy ujrzeć Jezusa”. Filip poszedł i powiedział Andrzejowi. Z kolei Andrzej i Filip poszli i powiedzieli Jezusowi.
    A Jezus dał im taką odpowiedź: „Nadeszła godzina, aby został uwielbiony Syn Człowieczy. Zaprawdę, zaprawdę powiadam wam: Jeżeli ziarno pszenicy wpadłszy w ziemię nie obumrze, zostanie tylko samo, ale jeżeli obumrze, przynosi plon obfity. Ten, kto miłuje swoje życie, traci je, a kto nienawidzi swego życia na tym świecie, zachowa je na życie wieczne. A kto by chciał Mi służyć, niech idzie za Mną, a gdzie Ja jestem, tam będzie i mój sługa. A jeśli ktoś Mi służy, uczci go mój Ojciec.
    Teraz dusza moja doznała lęku i cóż mam powiedzieć? Ojcze, wybaw Mnie od tej godziny. Nie, właśnie dlatego przyszedłem na tę godzinę. Ojcze, uwielbij imię Twoje”.
    Wtem rozległ się głos z nieba: „I uwielbiłem, i znowu uwielbię”. Tłum stojący usłyszał to i mówił: „Zagrzmiało!” Inni mówili: „Anioł przemówił do Niego”. Na to rzekł Jezus: „Głos ten rozległ się nie ze względu na Mnie, ale ze względu na was. Teraz odbywa się sąd nad tym światem. Teraz władca tego świata zostanie precz wyrzucony. A Ja, gdy zostanę nad ziemię wywyższony, przyciągnę wszystkich do siebie”.
    To powiedział zaznaczając, jaką śmiercią miał umrzeć.
    
    Oto słowo Pańskie.
`,
  ],
  //33 22/3
  [
    `
    EWANGELIA  (J 8,1-11)
`,

    `
    Chrystus ocala cudzołożnicę
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus udał się na Górę Oliwną, ale o brzasku zjawił się znów w świątyni. Wszystek lud schodził się do Niego, a On usiadłszy nauczał.
    Wówczas uczeni w Piśmie i faryzeusze przyprowadzili do Niego kobietę, którą pochwycono na cudzołóstwie, a postawiwszy ją na środku, powiedzieli do Niego: „Nauczycielu, kobietę tę dopiero pochwycono na cudzołóstwie. W Prawie Mojżesz nakazał nam takie kamienować. A Ty co mówisz?” Mówili to wystawiając Go na próbę, aby mieli o co Go oskarżyć.
    Lecz Jezus nachyliwszy się pisał palcem po ziemi. A kiedy w dalszym ciągu Go pytali, podniósł się i rzekł do nich: „Kto z was jest bez grzechu, niech pierwszy rzuci na nią kamień”. I powtórnie nachyliwszy się, pisał na ziemi.
    Kiedy to usłyszeli, wszyscy jeden po drugim zaczęli odchodzić, poczynając od starszych. Pozostał tylko Jezus i kobieta, stojąca na środku.
    Wówczas Jezus podniósłszy się rzekł do niej: „Niewiasto, gdzież oni są? Nikt cię nie potępił?” A ona odrzekła: „Nikt, Panie!” Rzekł do niej Jezus: „I Ja ciebie nie potępiam.- Idź, a od tej chwili już nie grzesz”.
    
    Oto słowo Pańskie.
`,
  ],
  //34 23/3
  [
    `
    EWANGELIA (J 8,21-30)
`,

    `
    Gdy wywyższycie Syna Człowieczego, poznacie, że Ja jestem
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus powiedział do faryzeuszów: „Ja odchodzę, a wy będziecie Mnie szukać i w grzechu swoim pomrzecie. Tam, gdzie Ja idę, wy pójść nie możecie”.
    Rzekli więc do Niego Żydzi: „Czyżby miał sam siebie zabić, skoro powiada: Tam, gdzie Ja idę, wy pójść nie możecie?”
    A On rzekł do nich: „Wy jesteście z niskości, a Ja jestem z wysoka. Wy jesteście z tego świata, Ja nie jestem z tego świata. Powiedziałem wam, że pomrzecie w grzechach swoich. Tak, jeżeli nie uwierzycie, że Ja jestem, pomrzecie w grzechach swoich”.
    Powiedzieli do Niego: „Kimże Ty jesteś?”
    Odpowiedział im Jezus: „Przede wszystkim po cóż jeszcze do was mówię? Wiele mam o was do powiedzenia i do sądzenia. Ale Ten, który Mnie posłał, jest prawdziwy, a Ja mówię wobec świata to, co usłyszałem od Niego”. A oni nie pojęli, że im mówił o Ojcu.
    Rzekł więc do nich Jezus: „Gdy wywyższycie Syna Człowieczego, wtedy poznacie, że Ja jestem i że Ja nic od siebie nie czynię, ale że to mówię, czego Mnie Ojciec nauczył. A Ten, który Mnie posłał, jest ze Mną: nie pozostawił Mnie samego, bo Ja zawsze czynię to, co się Jemu podoba”.
    Kiedy to mówił, wielu uwierzyło w Niego.
    Oto słowo Pańskie.
`,
  ],
  //35 /24/3
  [
    `
    EWANGELIA  (J 8,31-42)
`,

    `
    Prawdziwe wyzwolenie przez Chrystusa
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus powiedział do Żydów, którzy Mu uwierzyli: „Jeżeli będziecie trwać w nauce mojej, będziecie prawdziwie moimi uczniami i poznacie prawdę, a prawda was wyzwoli”.
    Odpowiedzieli Mu: „Jesteśmy potomstwem Abrahama i nigdy nie byliśmy poddani w niczyją niewolę. Jakżeż Ty możesz mówić: «Wolni będziecie?»”
    Odpowiedział im Jezus: „Zaprawdę, zaprawdę powiadam wam: Każdy, kto popełnia grzech, jest niewolnikiem grzechu. A niewolnik nie przebywa w domu na zawsze, lecz Syn przebywa na zawsze. Jeżeli więc Syn was wyzwoli, wówczas będziecie rzeczywiście wolni. Wiem, że jesteście potomstwem Abrahama, ale wy usiłujecie Mnie zabić, bo nie przyjmujecie mojej nauki. Głoszę to, co widziałem u mego Ojca, wy czynicie to, coście słyszeli od waszego ojca”.
    W odpowiedzi rzekli do Niego: „Ojcem naszym jest Abraham”.
    Rzekł do nich Jezus: „Gdybyście byli dziećmi Abrahama, to byście pełnili czyny Abrahama. Teraz usiłujecie Mnie zabić, człowieka, który wam powiedział prawdę usłyszaną u Boga. Tego Abraham nie czynił. Wy pełnicie czyny ojca waszego”.
    Rzekli do Niego: „Myśmy się nie narodzili z nierządu, jednego mamy Ojca - Boga”.
    Rzekł do nich Jezus: „Gdyby Bóg był waszym ojcem, to i Mnie byście miłowali. Ja bowiem od Boga wyszedłem i przychodzę. Nie wyszedłem od siebie, lecz On Mnie posłał”.
     
    Oto słowo Pańskie.
`,
  ],
  //36 /25/3
  [
    `
    EWANGELIA (Łk 1,26-38)
`,

    `
    Maryja pocznie i porodzi Syna
`,

    `
    Słowa Ewangelii według świętego Łukasza.
`,

    `
    Bóg posłał anioła Gabriela do miasta w Galilei, zwanego Nazaret, do Dziewicy poślubionej mężowi, imieniem Józef, z rodu Dawida; a Dziewicy było na imię Maryja.
    Anioł wszedł do Niej i rzekł: „Bądź pozdrowiona, pełna łaski, Pan z Tobą, błogosławiona jesteś między niewiastami”.
    Ona zmieszała się na te słowa i rozważała, co miałoby znaczyć to pozdrowienie.
    Lecz anioł rzekł do Niej: „Nie bój się, Maryjo, znalazłaś bowiem łaskę u Boga. Oto poczniesz i porodzisz Syna, któremu nadasz imię Jezus. Będzie On wielki i będzie nazwany Synem Najwyższego, a Pan Bóg da Mu tron Jego praojca, Dawida. Będzie panował nad domem Jakuba na wieki, a Jego panowaniu nie będzie końca”.
    Na to Maryja rzekła do anioła: „Jakże się to stanie, skoro nie znam męża?”
    Anioł jej odpowiedział: „Duch święty zstąpi na Ciebie i moc Najwyższego osłoni Cię. Dlatego też Święte, które się narodzi, będzie nazwane Synem Bożym. A oto również krewna Twoja, Elżbieta, poczęła w swej starości syna, i jest już w szóstym miesiącu ta, która uchodzi za niepłodną. Dla Boga bowiem nie ma nic niemożliwego”.
    Na to rzekła Maryja: „Oto ja służebnica Pańska, niech mi się stanie według twego słowa”. Wtedy odszedł od Niej anioł.
     
    Oto słowo Pańskie.
`,
  ],
  //37 26/3
  [
    `
    EWANGELIA  (J 10,31-42)
`,

    `
    Jezus oskarżony o bluźnierstwo
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Żydzi porwali kamienie, aby Jezusa ukamienować. Odpowiedział im Jezus: „Ukazałem wam wiele dobrych czynów pochodzących od Ojca. Za który z tych czynów chcecie Mnie ukamienować ?”
    Odpowiedzieli Mu Żydzi: „Nie chcemy Cię kamienować za dobry czyn, ale za bluźnierstwo, za to, że Ty będąc człowiekiem uważasz siebie za Boga”.
    Odpowiedział im Jezus: «Czyż nie napisano w waszym Prawie: «Ja rzekłem: Bogami jesteście?» Jeżeli Pismo nazywa bogami tych, do których skierowano słowo Boże — a Pisma nie można odrzucić - to jakżeż wy o Tym, którego Ojciec poświęcił i posłał na świat, mówicie: «Bluźnisz», dlatego że powiedziałem: Jestem Synem Bożym? Jeżeli nie dokonuję dzieł mojego Ojca, to Mi nie wierzcie. Jeżeli jednak dokonuję, to choćbyście Mnie nie wierzyli, wierzcie moim dziełom, abyście poznali i wiedzieli, że Ojciec jest we Mnie, a Ja w Ojcu”.
    I znowu starali się Go pojmać, ale On uszedł z ich rąk. I powtórnie udał się za Jordan, na miejsce, gdzie Jan poprzednio udzielał chrztu, i tam przebywał.
    Wielu przybyło do Niego, mówiąc, iż Jan wprawdzie nie uczynił żadnego znaku, ale że wszystko, co Jan o Nim powiedział, było prawdą. I wielu tam w Niego uwierzyło.
    Oto słowo Pańskie.
`,
  ],
  //38 27/3
  [
    `
    EWANGELIA  (J 11,45-57)
`,

    `
    Jezus umrze, aby zgromadzić rozproszone dzieci Boże
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Wielu spośród Żydów przybyłych do Marii, ujrzawszy to, czego Jezus dokonał, uwierzyło w Niego. Niektórzy z nich udali się do faryzeuszów i donieśli im, co Jezus uczynił.
    Wobec tego arcykapłani i faryzeusze zwołali Najwyższą Radę i rzekli: „Cóż my robimy wobec tego, że ten człowiek czyni wiele znaków? Jeżeli Go tak pozostawimy, to wszyscy uwierzą w Niego, i przyjdą Rzymianie, i zniszczą nasze miejsce święte i nasz naród”. Wówczas jeden z nich, Kajfasz, który w owym roku był najwyższym kapłanem, rzekł do nich: „Wy nic nie rozumiecie i nie bierzecie tego pod uwagę, że lepiej jest dla was, gdy jeden człowiek umrze za lud, niż miałby zginąć cały naród”.
    Tego jednak nie powiedział sam od siebie, ale jako najwyższy kapłan w owym roku wypowiedział proroctwo, że Jezus miał umrzeć za naród, a nie tylko za naród, ale także, by rozproszone dzieci Boże zgromadzić w jedno. Tego więc dnia postanowili Go zabić.
    Odtąd Jezus już nie występował wśród Żydów publicznie, tylko odszedł stamtąd do krainy w pobliżu pustyni, do miasteczka, zwanego Efraim, i tam przebywał ze swymi uczniami.
    A była blisko Pascha żydowska. Wielu przed Paschą udawało się z tej okolicy do Jerozolimy, aby się oczyścić. Oni więc szukali Jezusa i gdy stanęli w świątyni, mówili jeden do drugiego: „Cóż wam się zdaje? Czyżby nie miał przyjść na święto?”
    Arcykapłani zaś i faryzeusze wydali polecenie, aby każdy, ktokolwiek będzie wiedział o miejscu Jego pobytu, doniósł o tym, aby Go można było pojmać.
    Oto słowo Pańskie.
`,
  ],
  //39 28/3
  [
    `
    EWANGELIA (J 12,12-16)
`,

    `
    Wjazd Jezusa do Jerozolimy
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Wielki tłum, który przybył na święto, usłyszawszy, że Jezus przybywa do Jerozolimy, wziął gałązki palmowe i wybiegł Mu naprzeciw. Wołali: „Hosanna. Błogosławiony, który przychodzi w imię Pańskie” oraz: „Król izraelski!”
    A gdy Jezus znalazł osiołka, dosiadł go, jak jest napisane:
    „Nie bój się, Córo Syjońska, oto Król twój przychodzi, siedząc na oślęciu”.
    Jego uczniowie początkowo tego nie rozumieli. Ale gdy Jezus został uwielbiony, wówczas przypomnieli sobie, że to o Nim było napisane i że tak Mu uczynili.
    
    Oto słowo Pańskie.
`,
  ],
  //40 29/3
  [
    `
    EWANGELIA (J 12,1-11)
`,

    `
    Namaszczenie w Betanii
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Na sześć dni przed Paschą Jezus przybył do Betanii, gdzie mieszkał Łazarz, którego Jezus wskrzesił z martwych. Urządzono tam dla Niego ucztę. Marta posługiwała, a Łazarz był jednym z zasiadających z Nim przy stole. Maria zaś wzięła funt szlachetnego i drogocennego olejku nardowego i namaściła Jezusowi nogi, a włosami swymi je otarła. A dom napełnił się wonią olejku.
    Na to rzekł Judasz Iskariota, jeden z uczniów Jego, ten, który Go miał wydać: „Czemu to nie sprzedano tego olejku za trzysta denarów i nie rozdano ich ubogim?” Powiedział zaś to nie dlatego, jakoby dbał o biednych, ale ponieważ był złodziejem, i mając trzos wykradał to, co składano.
    Na to Jezus powiedział: „Zostaw ją! Przechowała to, aby Mnie namaścić na dzień mojego pogrzebu. Bo ubogich zawsze macie u siebie, ale Mnie nie zawsze macie”.
    Wielki tłum Żydów dowiedział się, że tam jest; a przybyli nie tylko ze względu na Jezusa, ale także by ujrzeć Łazarza, którego wskrzesił z martwych. Arcykapłani zatem postanowili stracić również Łazarza, gdyż wielu z jego powodu odłączyło się od Żydów i uwierzyło w Jezusa.
    
    
    Oto słowo Pańskie.
`,
  ],
  //41 30/3
  [
    `
    EWANGELIA (J 13,21-33.36-38)
`,

    `
    Zapowiedź zdrady Judasza i zaparcia się Piotra
`,

    `
    Słowa Ewangelii według świętego Jana.
`,

    `
    Jezus w czasie wieczerzy z uczniami swoimi doznał głębokiego wzruszenia i tak oświadczył: „Zaprawdę, zaprawdę powiadam wam: Jeden z was Mnie zdradzi”. Spoglądali uczniowie jeden na drugiego niepewni, o kim mówi.
    Jeden z uczniów Jego, ten, którego Jezus miłował, spoczywał na Jego piersi. Jemu to dał znak Szymon Piotr i rzekł do niego: „Kto to jest? O kim mówi?” Ten oparł się zaraz na piersi Jezusa i rzekł do Niego: „Panie, kto to jest?”
    Jezus odpowiedział: „To ten, dla którego umaczam kawałek chleba i podam mu”. Umoczywszy więc kawałek chleba, wziął i podał Judaszowi, synowi Szymona Iskarioty. A po spożyciu kawałka chleba wszedł w niego szatan.
    Jezus zaś rzekł do niego: „Co chcesz czynić, czyń prędzej”. Nikt jednak z biesiadników nie rozumiał, dlaczego mu to powiedział. Ponieważ Judasz miał pieczę nad trzosem, niektórzy sądzili, że Jezus powiedział do niego: „Zakup, czego nam potrzeba na święta”, albo żeby dał coś ubogim. On zaś po spożyciu kawałka chleba zaraz wyszedł. A była noc.
    Po jego wyjściu rzekł Jezus: „Syn Człowieczy został teraz otoczony chwałą, a w Nim Bóg został chwałą otoczony. Jeżeli Bóg został w Nim chwałą otoczony, to i Bóg Go otoczy chwałą w sobie samym, i to zaraz Go chwałą otoczy. Dzieci, jeszcze krótko jestem z wami. Będziecie Mnie szukać, ale jak to Żydom powiedziałem, tak i wam teraz mówię, dokąd Ja idę, wy pójść nie możecie”.
    Rzekł do Niego Szymon Piotr: „Panie, dokąd idziesz?”
    Odpowiedział mu Jezus: „Dokąd Ja idę, ty teraz za Mną pójść nie możesz, ale później pójdziesz”.
    Powiedział Mu Piotr: „Panie, dlaczego teraz nie mogę pójść za Tobą? Życie moje oddam za Ciebie”.
    Odpowiedział Jezus: „Życie swoje oddasz za Mnie? Zaprawdę, zaprawdę powiadam ci: Kogut nie zapieje, aż ty trzy razy się Mnie wyprzesz”.
    
    
    Oto słowo Pańskie.
`,
  ],
  //42 31/3
  [
    `
    EWANGELIA (Mt 26,14-25)
`,

    `
    Zdrada Judasza
`,

    `
    Słowa Ewangelii według świętego Mateusza.
`,

    `
    Jeden z Dwunastu, imieniem Judasz Iskariota, udał się do arcykapłanów i rzekł: „Co chcecie mi dać, a ja wam Go wydam”. A oni wyznaczyli mu trzydzieści srebrników. Odtąd szukał sposobności, żeby Go wydać.
    W pierwszy dzień Przaśników przystąpili do Jezusa uczniowie i zapytali Go: „Gdzie chcesz, żebyśmy Ci przygotowali Paschę do spożycia ?”
    On odrzekł: „Idźcie do miasta, do znanego nam człowieka i powiedzcie mu: «Nauczyciel mówi: Czas mój jest bliski; u ciebie chcę urządzić Paschę z moimi uczniami»”. Uczniowie uczynili tak, jak im polecił Jezus, i przygotowali Paschę.
    Z nastaniem wieczoru zajął miejsce u stołu razem z dwunastu uczniami. A gdy jedli, rzekł: „Zaprawdę powiadam wam: jeden z was Mnie zdradzi”.
    Zasmuceni tym bardzo, zaczęli pytać jeden przez drugiego: „Chyba nie ja, Panie?”
    On zaś odpowiedział: „Ten, który ze Mną rękę zanurza w misie, on Mnie zdradzi. Wprawdzie Syn Człowieczy odchodzi, jak o Nim jest napisane; lecz biada temu człowiekowi, przez którego Syn Człowieczy będzie wydany. Byłoby lepiej dla tego człowieka, gdyby się nie narodził”.
    Wtedy Judasz, który Go miał zdradzić, rzekł: „Czy nie ja, Rabbi?” Mówi mu: „Tak jest, ty”.
    
    Oto słowo Pańskie.
`,
  ],
  // 43 1/4
  [
    `
    EWANGELIA (J 13, 1-15)
`,

    `
Do końca ich umiłował
`,

    `
Słowa Ewangelii według Świętego Jana
`,

    `
Było to przed Świętem Paschy. Jezus, wiedząc, że nadeszła godzina Jego, by przeszedł z tego świata do Ojca, umiłowawszy swoich na świecie, do końca ich umiłował.
W czasie wieczerzy, gdy diabeł już nakłonił serce Judasza Iskarioty, syna Szymona, aby Go wydał, Jezus, wiedząc, że Ojciec oddał Mu wszystko w ręce oraz że od Boga wyszedł i do Boga idzie, wstał od wieczerzy i złożył szaty. A wziąwszy prześcieradło, nim się przepasał. Potem nalał wody do misy. I zaczął obmywać uczniom nogi i ocierać prześcieradłem, którym był przepasany.
Podszedł więc do Szymona Piotra, a on rzekł do Niego: «Panie, Ty chcesz mi umyć nogi?». Jezus mu odpowiedział: «Tego, co Ja czynię, ty teraz nie rozumiesz, ale poznasz to później». Rzekł do Niego Piotr: «Nie, nigdy mi nie będziesz nóg umywał».
Odpowiedział mu Jezus: Jeśli cię nie umyję, nie będziesz miał udziału ze Mną». Rzekł do Niego Szymon Piotr: «Panie, nie tylko nogi moje, ale i ręce, i głowę!».
Powiedział do niego Jezus: «Wykąpany potrzebuje tylko nogi sobie umyć, bo cały jest czysty. I wy jesteście czyści, ale nie wszyscy». Wiedział bowiem, kto Go wyda, dlatego powiedział: «Nie wszyscy jesteście czyści».
A kiedy im umył nogi, przywdział szaty i znów zajął miejsce przy stole, rzekł do nich: «Czy rozumiecie, co wam uczyniłem? Wy Mnie nazywacie „Nauczycielem” i „Panem”, i dobrze mówicie, bo nim jestem. Jeżeli więc Ja, Pan i Nauczyciel, umyłem wam nogi, to i wy powinniście sobie nawzajem umywać nogi. Dałem wam bowiem przykład, abyście i wy tak czynili, jak Ja wam uczyniłem».

Oto słowo Pańskie.
`,
  ],
  //45
  [],
  //46
  [],
];

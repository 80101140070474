import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPosts } from '../redux/postsSlice';
import PostsList from 'components/PostsList/PostsList';
import { Button } from '@material-ui/core';

export default function PostsContainer() {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts.list?.groupedPosts?.others);
  const allPosts = useSelector(state => state.posts.list?.data)
  const after = useSelector(
    (state) => state.posts.list?.paging?.cursors?.after
  );

  useEffect(() => {
    !allPosts?.length && dispatch(fetchPosts());

    allPosts?.length && !posts?.length && dispatch(fetchPosts(after))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPosts]);
  return (
    <div>
      <PostsList posts={posts || []} />
      {posts?.length && (
        <div style={{ margin: '20px auto', textAlign: 'center' }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => after && dispatch(fetchPosts(after))}
          >
            Więcej postów
          </Button>
        </div>
      )}
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';
import { getFbEvents } from '../api/apiCalls';

const initState = {
	list: [],
	status: '',
	error: null,
};

const eventsSlice = createSlice({
	name: 'events',
	initialState: initState,
	reducers: {
		requestEvents(state) {
			state.status = 'pending';
			state.error = null;
		},
		receivedEvents(state, action) {
			state.list = action.payload;
			state.status = 'success';
			state.error = null;
		},
		rejectedEvents(state, action) {
			state.status = 'rejected';
			state.error = action.payload.error.message;
		},
	},
});

const { actions, reducer } = eventsSlice;
export const { requestEvents, receivedEvents, rejectedEvents } = actions;
export default reducer;

// thunks

export function fetchEvents() {
	return async dispatch => {
		dispatch(requestEvents());
		try {
			const eventsData = await getFbEvents();
			dispatch(receivedEvents(eventsData));
		} catch (err) {
			console.log(err);
			dispatch(rejectedEvents(err));
		}
	};
}

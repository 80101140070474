import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import logo from "assets/msjlogo.png";
import "./Navigation.scss";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "rgb(241, 243, 245)",
    // background: 'linear-gradient(45deg, rgb(185, 174, 174), rgb(106, 97, 97))',
    color: "#1d2b36",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0 16px 0 16px",
  },
  logo: {
    display: "flex",
    // justifyContent: 'space-evenly',
  },
  menuItems: {
    display: "flex",

    // justifyContent: 'space-evenly',
  },
  menuButton: {
    // display: 'flex',
    paddingLeft: "12px",
    // justifyContent: 'space-evenly',
  },
  menuIcon: {
    padding: "6px",
  },
  scrollToTop: {
    position: "fixed",
    zIndex: 99,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface MenuItem {
  link: string;
  title: string;
  inSidebar?: boolean;
}

const menuItems: MenuItem[] = [
  // { link: "wielkipost", title: "Wielki Post", inSidebar: false },
  // { link: "exodus", title: "Exodus", inSidebar: false },
  { link: "posty", title: "Posty", inSidebar: false},
  { link: "wydarzenia", title: "Wydarzenia", inSidebar: false },
  { link: "o-nas", title: "O nas", inSidebar: true },
  { link: "modlitwy", title: "Modlitwy", inSidebar: true },
  { link: "nasz-oredownik", title: "Nasz orędownik", inSidebar: true },
  { link: "kalendarz", title: "Kalendarz", inSidebar: true },
  // { link: "pray-vlog", title: "#pray_vlog", inSidebar: true },
];

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        className={classes.scrollToTop}
      >
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function Navigation(props) {
  const [isDrawerOpen, setIsOpenDrawer] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpenDrawer(open);
  };

  const classes = useStyles();
  const poWIELKANOCY =
    differenceInCalendarDays(new Date("2021-04-04"), new Date()) < 1;

  return (
    <div className={classes.root}>
      <span id="back-to-top-anchor" />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logo}>
            <NavLink to="/">
              <Button color="inherit">
                <img src={logo} width={55} height={45} alt="home" />
              </Button>
            </NavLink>
          </div>
          <div className={classes.menuItems}>
            {menuItems
              .map((item: MenuItem) => (
                <NavLink
                  key={item.link}
                  to={`/${item.link}`}
                  className={`${
                    item.inSidebar
                      ? "navigation__item navigation__item--additional"
                      : "navigation__item"
                  }`}
                  activeClassName="navigation__item--active"
                >
                  <Button color="inherit">{item.title}</Button>
                </NavLink>
              ))}
          </div>
          <div className="navigation__menu-icon">
            <IconButton
              onClick={toggleDrawer(true)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor={"right"}
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
      >
        <List>
          {menuItems
            .filter((item: MenuItem) => item.inSidebar)
            .map((item: MenuItem) => (
              <ListItem button key={item.link} onClick={toggleDrawer(false)}>
                <NavLink
                  to={`/${item.link}`}
                  // className="navigation__item navigation__item--additional"
                  activeClassName="navigation__item--active"
                >
                  <Button color="inherit">{item.title}</Button>
                  {/* <ListItemText primary={item.title} /> */}
                </NavLink>
              </ListItem>
            ))}
        </List>
      </Drawer>
      <ScrollTop {...props}>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
}

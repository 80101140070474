import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
// import film from '../assets/testlive.mpd';
import ReactPlayer from 'react-player';

export default function VideoSharePost({ post }) {
  return (
    <>
      <ReactPlayer
        width="100%"
        height="100%"
        // style={{ margin: '0 auto 20px', maxHeight: '480px' }}
        // url='https://dash.akamaized.net/envivio/EnvivioDash3/manifest.mpd'
        url={post.attachments.data[0].url}
        controls
        className="post-item__react-player"
      />
      <a
        href={post.attachments.data[0].url}
        target="_blank"
        rel="noopener noreferrer"
      >
        OGLĄDAJ NA FACEBOOK'U
      </a>
    </>
  );
}

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

export const store = (state) =>
	configureStore({
		reducer: rootReducer,
		preloadedState: state, // this is only for easy testing, can provide any mock state to store Provider
	});

export default store();

import React from 'react';
import Typogaphy from '@material-ui/core/Typography';
import './Footer.scss';

export default function Footer() {
	return (
		<div className='footer'>
			<Typogaphy>&copy; {new Date().getFullYear()} MSJ Glasgow</Typogaphy>
			<Typogaphy>
				<a href='https://facebook.com/msjglasgow' rel='noopener noreferrer' target='blank'>
					facebook.com/msjglasgow
				</a>
			</Typogaphy>
			<Typogaphy>Kontakt: kontakt@msjglasgow.org</Typogaphy>
		</div>
	);
}

import React from "react";
import ReactDOM from "react-dom";
import "./reset.css";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";
import store from "./redux/store";
import "typeface-lato";
// import "typeface-roboto";
// import swConfig from "./swConfig";
// import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "Lato",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

ReactDOM.render(
  <Provider store={store}>
    {/* <CssBaseline /> */}
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// below would require redux actions to be set up to display banners - offline use and new content available
// https://medium.com/better-programming/let-users-know-when-you-have-updated-your-service-worker-in-create-react-app-b0c2701995b3
// serviceWorker.register({
//   onSuccess: () => store.dispatch({ type: 'SW_INIT' }),
//   onUpdate: reg => store.dispatch({ type:' SW_UPDATE', payload: reg }),
// });

// below reloads the page when there is new version available without any banners etc
interface ServiceWorkerStateChangeEventTarget extends EventTarget {
  state: ServiceWorkerState;
}

serviceWorkerRegistration.register({
  onUpdate: (registration: { waiting: ServiceWorker | null }) => {
    const sw: ServiceWorker | null = registration.waiting;

    // listen new service worker actived, reload page
    sw?.addEventListener("statechange", (e) => {
      const state = (e?.target as ServiceWorkerStateChangeEventTarget).state;
      state === "activated" && window.location.reload();
    });

    // send message to current service worker active new worker
    sw?.postMessage({ type: "SKIP_WAITING" });
  },
});

// serviceWorker.unregister()

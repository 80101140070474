import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function SectionTitle({ title }) {
	return (
		<Typography component='h2' variant='h3' gutterBottom>
			{title}
		</Typography>
	);
}

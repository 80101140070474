import React, { useState, useEffect } from "react";
import { dateWithTime, time } from "../../utils";
import "./EventCard.scss";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import EventDetailsDialog from "components/EventDetailsDialog";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  card: {
    height: "100%",
    maxWidth: "850px",
    margin: "0 auto",
  },
  cardActionArea: {
    height: "100%",
    // display: 'flex',
  },
  media: {
    height: 0,
    padding: "26%",
    backgroundPosition: "top",
  },
});
interface EventCardProps {
  event: any;
  showImage?: boolean;
  disableDialog?: boolean;
}
export default function EventCard({
  event,
  showImage,
  disableDialog,
}: EventCardProps) {
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    let start = "";
    let end = "";
    if (event.start_time) {
      start = dateWithTime(event.start_time);
      end = event.end_time
        ? start.slice(0, 10) === dateWithTime(event.end_time).slice(0, 10)
          ? time(event.end_time)
          : dateWithTime(event.end_time)
        : "";

      setStartTime(start);
      setEndTime(end);
    }
  }, [event]);
  return (
    // <Container maxWidth='md'>
    <>
      <Card className={classes.card} elevation={5}>
        <CardActionArea
          onClick={() => !disableDialog && setIsOpenDialog(true)}
          className={classes.cardActionArea}
        >
          {showImage && (
            <CardMedia
              className={classes.media}
              image={event.cover.source}
              title={event.name}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="h6">
              {event.name}
            </Typography>
            <Typography gutterBottom variant="subtitle1">
              {startTime} - {endTime}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      {!disableDialog && (
        <EventDetailsDialog
          open={isOpenDialog}
          handleDialogClose={() => setIsOpenDialog(false)}
          event={event}
        />
      )}
    </>
    // </Container>
  );
}

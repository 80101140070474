import { Typography } from "@material-ui/core";
import React, { FC } from "react";

export const CardTitle: FC<{
  title: string;
  small?: boolean;
  fontSize?: "small" | "normal";
}> = ({ title, small, fontSize }) => {
  return (
    <Typography
      variant={small || fontSize === "small" ? "h6" : "h5"}
      style={{
        borderBottom: `${small ? "" : "4px solid darkslateblue"}`,
        display: "inline-block",
        marginBottom: "10px",
      }}
    >
      {title}
    </Typography>
  );
};

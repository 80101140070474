import React from "react";
import Grid from "@material-ui/core/Grid";
import SectionTitle from "components/ui/SectionTitle";
import PostItem from "components/PostItem";

export default function PostsList(props: { posts: any[] }) {
  return (
    <div>
      <SectionTitle title="Posty" />
      <Grid
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        container
        spacing={2}
      >
        {props.posts
          .filter((post) => post.status_type !== "wall_post")
          .map((post: { id: any }) => (
            <Grid item xs={12} key={post.id}>
              <PostItem post={post} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}

let mockPosts = false;
let mockEvents = false;

if (process.env.NODE_ENV === "production") {
  mockPosts = false;
  mockEvents = false;
}

export const getFbFeed = async (after: any) => {
  if (mockPosts) {
    const data = await import(`../mockData/feed.json`);
    return data;
  }

  let params = {
    access_token: process.env.REACT_APP_FB_ACCESS_TOKEN,
    fields:
      "full_picture,created_time,event,id,message,properties,attachments{media,url,description,title,type,subattachments},status_type",
    after: "",
  };
  if (after) {
    params = {
      ...params,
      after,
    };
  }

  return new Promise((resolve, reject) => {
    (window as any).FB.api(
      "/msjglasgow/feed",
      "GET",

      params,
      function (response) {
        
        response.data && resolve(response);
        !response.data && reject(response);
      }
    );
  });
};

export const getFbEvents = async () => {
  if (mockEvents) {
    const data = await import(`../mockData/events.json`);
    return data;
  }

  return new Promise((resolve, reject) => {
    (window as any).FB.api(
      "/msjglasgow/events",
      "GET",
      {
        access_token: process.env.REACT_APP_FB_ACCESS_TOKEN,
        fields: "cover,description,name,place,start_time,end_time",
      },
     // access toekn to update bc006f72805dddd27ab81559f2b5f8c1
      function (response) {
        // Insert your code here
        response.data && resolve(response);
        !response.data && reject(response);
      }
    );
  });
};

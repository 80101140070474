import React from 'react';
import Typography from '@material-ui/core/Typography';
import './SmallLink.scss';

export default function SmallLink({ to, children }) {
	return (
		<Typography variant='body1'>
			<a className='small-link' href={to} target="blank" rel="noopener noreferrer">
				{children}
			</a>
		</Typography>
	);
}

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { VideoLivePost, VideoSharePost } from "./PostTypes";
import { dateWithTime} from "utils";
import "./PostItem.scss";
import SmallLink from "components/ui/SmallLink";

const useStyles = makeStyles({
  postText: {
    whiteSpace: "pre-line",
    marginTop: "20px",
    wordBreak: "break-word",
  },
});

export default function PostItem({ post }) {
  const classes = useStyles();

  const isVideo = post.attachments?.data[0].type === "video_autoplay";

  function getVideoType(post) {
    // currently only gets type for live video and share video
    // TO DO: detect all post types => post, event added, share video/post/link
    if (
      (post.attachments?.data[0].type === "video_autoplay" ||
        post.attachments?.data[0].type === "share") &&
      post.attachments?.data[0].media.source
    ) {
      return <VideoLivePost post={post} />;
    }
    if (
      post.attachments?.data[0].type === "video_autoplay" &&
      !post.attachments?.data[0].media.source
    ) {
      return <VideoSharePost post={post} />;
    }
  }

  const isEvent = post.attachments?.data[0]?.type === "event";

  return (
    <React.Fragment>
      <Container maxWidth="md">
        <Paper style={{ padding: "40px 20px" }} elevation={5}>
          <Typography component="div">
            <Typography component="p" style={{ textAlign: "right" }}>
              {dateWithTime(post.created_time)}
            </Typography>
            {(post.attachments?.data[0].type === "share" || isVideo) && (
              <Typography variant="h6">
                {post.attachments?.data[0].title}
              </Typography>
            )}
            <Typography component="p" className={classes.postText}>
              {post.message}
            </Typography>
            <br />

            {post.attachments?.data[0].type === "video_autoplay" ||
            (post.attachments?.data[0].type === "share" &&
              post.attachments?.data[0].media?.source)
              ? getVideoType(post)
              : post.full_picture && (
                  <img
                    src={post.full_picture}
                    alt=""
                    style={{ margin: "0 auto" }}
                  />
                )}

            <Typography className={classes.postText} component="p">
              <>
                {isEvent
                  ? `Wydarzenie: ${post.attachments?.data[0].title}`
                  : post.attachments?.data[0]?.description}
              </>
            </Typography>
            <br />
            {post.attachments?.data[0].url && (
              <SmallLink to={post.attachments?.data[0].url}>Link</SmallLink>
            )}
          </Typography>
        </Paper>
      </Container>
    </React.Fragment>
  );
}

import SmallLink from "components/ui/SmallLink";
import React from "react";

// import { makeStyles } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
// import film from '../assets/testlive.mpd';
import ReactPlayer from "react-player";

export default function VideoLivePost({ post }) {
  return (
    <>
      <ReactPlayer
        width="100%"
        height="550px"
        // style={{ margin: '0 auto 20px', maxHeight: '480px' }}
        // url='https://dash.akamaized.net/envivio/EnvivioDash3/manifest.mpd'
        url={post.attachments.data[0].media.source}
        controls
        className="post-item__react-player"
      />
      <SmallLink to={post.attachments.data[0].url}>
        OGLĄDAJ NA FACEBOOK'U
      </SmallLink>
    </>
  );
}

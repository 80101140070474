import React from "react";

interface SpacingProps {
  children?: any;
  top?: undefined | 1 | 2 | 3 | 4;
  bottom?: undefined | 1 | 2 | 3 | 4;
  vertical?: undefined | 1 | 2 | 3 | 4;
}

export const Spacing: React.FC<SpacingProps> = ({
  children,
  top,
  bottom,
  vertical,
}) => {
  const getSize = (size: number) => {
    switch (size) {
      case 1:
        return "8px";
      case 2:
        return "16px";
      case 3:
        return "24px";
      case 4:
        return "32px";
      default:
        return "0px";
    }
  };
  const style = {
    marginTop: top && getSize(top),
    marginBottom: bottom && getSize(bottom),
    margin: vertical && `${getSize(vertical)} 0px`,
  };
  return children ? <div style={style}>{children}</div> : <div style={style} />;
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import HomeContainer from "./HomeContainer";
import EventsContainer from "./EventsContainer";
import PostsContainer from "./PostsContainer";
import CalendarContainer from "./CalendarContainer";
import AboutPage from "pages/AboutPage";
import OredownikPage from "pages/OredownikPage";
import ModlitwyPage from "pages/ModlitwyPage";
import PrayVlogPage from "pages/PrayVlogPage";
import { ExodusPage } from "pages/ExodusPage";
import { WielkiPostPageDev } from "pages/WielkiPostPageDev";
import { MDSPage } from "pages/MDS";

export default function AppRoute() {
  return (
    <div className="m-10 App" style={{ paddingBottom: "55px" }}>
      <Switch>
        <Route exact path="/">
          <HomeContainer />
        </Route>
        <Route exact path="/exodus">
          <ExodusPage />
        </Route>
        <Route path="/wydarzenia">
          <EventsContainer />
        </Route>
        <Route path="/prayvlog">
          <PrayVlogPage />
        </Route>
        <Route path="/posty">
          <PostsContainer />
        </Route>
        <Route path="/kalendarz">
          <CalendarContainer />
        </Route>
        <Route path="/o-nas">
          <AboutPage />
        </Route>
        <Route path="/nasz-oredownik">
          <OredownikPage />
        </Route>
        <Route path="/modlitwy">
          <ModlitwyPage />
        </Route>
        <Route path="/wielkipost">
          <WielkiPostPageDev />
        </Route>
        <Route path="/mds">
          <MDSPage />
        </Route>
      </Switch>
    </div>
  );
}

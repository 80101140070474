import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { dateWithTime, time } from 'utils';

const useStyles = makeStyles(theme => ({
	contentText: {
		paddingTop: '15px',
		whiteSpace: 'pre-line',
	},
	formControl: {
		marginTop: theme.spacing(2),
		minWidth: 120,
	},
	formControlLabel: {
		marginTop: theme.spacing(1),
	},
}));

export default function EventDetailsDialog(props) {
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');

	const { open, event, handleDialogClose } = props;

	useEffect(() => {
		const event = props.event;
		let start = '';
		let end = '';
		if (event.start_time) {
			start = dateWithTime(event.start_time);
			end =
				start.slice(0, 10) === dateWithTime(event.end_time).slice(0, 10)
					? time(event.end_time)
					: dateWithTime(event.end_time);

			setStartTime(start);
			setEndTime(end);
		}
	}, [props.event]);

	const classes = useStyles();

	return (
		<React.Fragment>
			<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleDialogClose} aria-labelledby='event-dialog'>
				<DialogTitle disableTypography={true} id='event-dialog'>
					<Typography gutterBottom variant='h5'>
						{event.name}
					</Typography>
					<Typography gutterBottom variant='subtitle1'>
						{/* {event.start_time && dateWithTime(event.start_time)} - {event.end_time && dateWithTime(event.end_time)} */}
						{startTime} - {endTime}
					</Typography>
					<Typography gutterBottom variant='subtitle1'>
						{event.place?.name}, {event.place?.location?.city}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<div style={{ maxHeight: '500px', overflow: 'hidden' }}>
						<img src={event.cover?.source} style={{ margin: '0 auto' }} alt='' />
					</div>
					<DialogContentText className={classes.contentText}>{event.description}</DialogContentText>
					<DialogContentText className={classes.contentText}>
						Miejsce: {event.place?.name}, {event.place?.location?.city}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose} color='primary'>
						Zamknij
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
